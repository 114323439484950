import { useState, useEffect } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { getImage } from '../utils/cockpit';

const LazyloadImage = (props) => {

  const { image, w, h, isBackgroundImage, desaturate } = props;
  const [imageSrc, setImageSrc] = useState('');
  const [imageIsLoaded, setImageIsLoaded] = useState(false);

  useEffect(() => {

    let img;
    const onImageLoad = () => {
      setImageIsLoaded(true);
    }

    if (image?.path) {
      const path = image.path;
      getImage(
        path,
        w ? w : 2500,
        h ? h : 2500,
        30,
        (imageUrl) => {
          const url = imageUrl;
          setImageSrc(url);
          img = document.createElement('img');
          img.addEventListener('load', onImageLoad);
          img.src = url;
        },
        desaturate ? ['desaturate'] : false
      );
    }

    return () => {
      if (img) {
        img.removeEventListener('load', onImageLoad);
      }
    }
  }, [image, w, h, desaturate]);

  
  return (
    <AnimatePresence>
      {
        image?.path && image.path !== '' && imageIsLoaded === true &&
        <motion.img
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className={`image--lazyload${isBackgroundImage === true ? ' background' : ''}`}
          src={imageSrc}
          alt={image.title}
        />
      }
      </AnimatePresence>
    )
}

export default LazyloadImage;