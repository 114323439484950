import { useState, useEffect } from 'react';

const colors = [
  '#188ECC'
];

const Header = (props) => {

  const { siteData, selectedLayer, selectedLocation } = props;
  const [backgroundColor] = useState(colors[Math.floor(Math.random() * colors.length)]);
  const [newspaperNameSuffix, setNewspaperNameSuffix] = useState('');

  useEffect(() => {
    if (selectedLocation._id && siteData.newspaperNameGenerator?.suffixes) {
      const { suffixes } = siteData.newspaperNameGenerator;
      if (suffixes.length > 0) {
        setNewspaperNameSuffix(suffixes[Math.floor(Math.random() * suffixes.length)]);
      }
    }
  }, [selectedLocation, siteData.newspaperNameGenerator]);

  return (
    <header
      className="archive__header"
      style={{
        backgroundColor: backgroundColor
      }}
    >
			<h1 className="archive__heading">{
				selectedLayer.title || selectedLocation.title ?
					selectedLayer.title && !selectedLocation.title ? selectedLayer.title :
						!selectedLayer.title && selectedLocation.title ? 'The ' + selectedLocation.title + ' ' + newspaperNameSuffix:
								'The ' + selectedLocation.title + ' ' + newspaperNameSuffix + ': ' + selectedLayer.title :
			'Archive'}</h1>
    </header>
  )
}

export default Header;