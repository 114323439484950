import { Fragment, useEffect, useState } from 'react';
import parse from 'html-react-parser';
import LazyloadImage from '../LazyloadImage';
import VideoPlayer from '../VideoPlayer';
import AudioPlayer from '../AudioPlayer';
import Font from './Font';

const FragmentContentText = (props) => {

  const { item } = props;
  const { value } = item;
	const [ formattedValue, setFormattedValue ] = useState('');

	useEffect(() => {
		const textElement = document.createElement('div');
		if (value) {
			textElement.innerHTML = value;

			const links = textElement.querySelectorAll('a');
			links.forEach(link => {
				if (link.href.indexOf(window.location.href) === -1) {
					link.target = '_blank';
					link.rel = 'noopener noreferrer';
				}
			});

			const formattedValue = textElement.innerHTML;
			setFormattedValue(formattedValue);
		}
	}, [ value ]);

  if (value) {
    return (
      <div className="fragment__content__item__content fragment__content__item__text col-xxs-12">
        { parse(formattedValue) }
      </div>
    )
  } else {
    return null;
  }
}

const FragmentContentImage = (props) => {
  const { item, archive } = props;

  return (
    <div className="fragment__content__item__content col-xxs-12">
      <LazyloadImage
        image={item.value}
        w={archive === true ? 128 : null}
        h={archive === true ? 128 : null}
        filter={archive === true ? ['desaturate'] : null}
      />
    </div>
  )
}

const FragmentContentVideo = (props) => {
  const { item } = props;

  return (
    <div className="fragment__content__item__content col-xxs-12">
        <VideoPlayer url={item.value} />
    </div>
  )
}

const FragmentContentVideoUrl = (props) => {

  const { item } = props;
  const { value } = item;

  return (
    <div className="fragment__content__item__content col-xxs-12">
      <VideoPlayer url={value} />
    </div>
  )
}

const FragmentContentFile = (props) => {
  const { item } = props;

  return (
    <div className="fragment__content__item__content col-xxs-12">
      <p>{item.value}</p>
    </div>
  )
}

const FragmentContentAudio = (props) => {
  const { item } = props;

  return (
    <div className="fragment__content__item__content col-xxs-12">
      <AudioPlayer url={item.value} />
    </div>
  )
}

const FragmentContentAudioUrl = (props) => {
  const { item } = props;
  const { value } = item;

  return (
    <div className="fragment__content__item__content col-xxs-12">
      <AudioPlayer url={value} />
    </div>
  )
}

const ContentItemContent = (props) => {

  const { item } = props;
  if (item.field.name === 'text') {
    return <FragmentContentText {...props} contentItem={item} />;
  } else if (item.field.name === 'image') {
    return <FragmentContentImage {...props} contentItem={item} />;
  } else if (item.field.name === 'video') {
    return <FragmentContentVideo {...props} contentItem={item} />;
  } else if (item.field.name === 'video_url') {
    return <FragmentContentVideoUrl {...props} contentItem={item} />;
  } else if (item.field.name === 'file') {
    return <FragmentContentFile {...props} contentItem={item} />;
  } else if (item.field.name === 'audio') {
    return <FragmentContentAudio {...props} contentItem={item} />;
  } else if (item.field.name === 'audio_url') {
    return <FragmentContentAudioUrl {...props} contentItem={item} />;
  } else {
    return null
  }
}

const ContentItem = (props) => {

  return (
    <li className="fragment__content__item row">
      <ContentItemContent {...props} />
    </li>
  )
}

const FragmentContent = (props) => {

  const { item, archive } = props;
  const { content } = item;

  return (
    <Fragment>
      <Font item={item} className={`fragment__content--${item._id}`} />
      <ul className={`fragment__content__rows container-fluid fragment__content--${item._id}`}>
        {
          content &&
          content[0] &&
          content.map((item, i) => (
            <ContentItem key={i} index={i} item={item} archive={archive} />
          ))
        }
      </ul>
    </Fragment>
  )
}

export default FragmentContent;