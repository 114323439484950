import { useRef, useState, useEffect, } from 'react';
import { Canvas, useThree, useFrame } from '@react-three/fiber';
import { useGLTF, useTexture } from '@react-three/drei';
import { CatmullRomCurve3, FrontSide } from 'three';
import * as THREE from 'three';
import WellModel from '../../assets/well/Well.glb';
import InfiniteTube from '../components/surprise/InfiniteTube';
import Coin from '../components/surprise/Coin';
import CoinSelector from '../components/surprise/CoinSelector';
import { motion, AnimatePresence } from 'framer-motion';
import Camera from '../components/surprise/Camera';
import Ground from '../components/surprise/Ground';
import { A11y, A11yAnnouncer } from '@react-three/a11y';
import hdri from '../../assets/the-galleries-panorama.jpeg';

const MouseMoveHelper = ({ group, coinIsTossed }) => {
  const { viewport } = useThree();

  useFrame(({ mouse }) => {
    if (group.current) {
      let rotationYTarget = ((mouse.x - viewport.width / 2) / viewport.width) * Math.PI * 0.6 - Math.PI * 0.3;
      let rotationXTarget = ((mouse.y + viewport.height / 2) / viewport.height) * Math.PI * 0.6 - Math.PI * 0.3;

      if (coinIsTossed === true) {
        rotationXTarget = 0;
        rotationYTarget = group.current.rotation.y;
      }

      group.current.rotation.y += (rotationYTarget - group.current.rotation.y) * 0.2;
      group.current.rotation.x += (rotationXTarget - group.current.rotation.x) * 0.2;
    }
  });

  return null;
}

const MallPanorama = () => {

  const mallTexture = useTexture(hdri);

  if (mallTexture.uuid) {
    return (
      <group>
        <mesh
          position={[0, -2, 0]}
          rotation={[0, 0, Math.PI]}
        >
          <sphereBufferGeometry args={[-9, 64, 64]} />
          <meshPhongMaterial side={FrontSide} needsUpdate={true}>
            <primitive
              attach="map"
              object={mallTexture}
              anisotropy={4}
              format={THREE.RGBAFormat}
              encoding={THREE.sRGBEncoding}
              mapping={THREE.EquirectangularReflectionMapping}
            />
          </meshPhongMaterial>
        </mesh>
        <mesh
          position={[0, -11.9, 0]}
          rotation={[-Math.PI / 2, 0, 0]}
        >
          <meshBasicMaterial color={0x000000} side={THREE.DoubleSide} />
          <planeBufferGeometry args={[30, 30]} />
        </mesh>
      </group>
    );
  } else {
    return null;
  }
}

const Well = (props) => {
  const { tossCoin } = props;
  let modelPrimitive = useGLTF(WellModel);
  const modelMesh = useRef();

  return (
    <mesh ref={modelMesh} position={[0, -2, -0.06]} onClick={tossCoin} onTouchStart={tossCoin}>
      <primitive object={modelPrimitive.scene} />
    </mesh>
  )
}

const SurpriseScene = (props) => {
  const { coinIsTossed, group, fog, coinModel } = props;

  return (
    <Canvas>
      <fog attach="fog" color={0x000000} near={0.2} far={38} ref={fog} />
      <group>
        <CoinSelector {...props} />
        <Camera {...props} coinIsTossed={coinIsTossed} />
        <ambientLight color={0xffffff} intensity={0.91} />
        <pointLight color={0xffffff} position={[0, 200, 0]} />
        <MouseMoveHelper {...props} />
        <group ref={group}>
          <group rotation={[0, Math.PI * 0.25, 0]}>
            <Ground />
            <MallPanorama />
            <A11y role="button" description="Click on the well to toss a coin and receive a surprise!">
              <Well {...props} />
            </A11y>
            <A11y role={coinIsTossed === true ? `button` : null} description={coinIsTossed === true ? `Click when you're ready to get your surprise!` : null}>
              <InfiniteTube {...props} />
            </A11y>
          </group>
        </group>
        {
          coinModel.unset !== true &&
          <Coin {...props} />
        }
      </group>
    </Canvas>
  )
}

const Surprise = (props) => {
  const { windowHeight, siteData, setActiveToolTip } = props;

  const [coinIsTossed, setCoinIsTossed] = useState(false);
  const [isReadyToGo, setIsReadyToGo] = useState(false);
  const [isTravellingOut, setIsTravellingOut] = useState(false);
  const [coinModel, setCoinModel] = useState({ unset: true });
  

  const coinMesh = useRef();
  const [cameraPos, setCameraPos] = useState({
    unset: true
  });

  useEffect(() => {
    if (coinIsTossed === true) {
      setActiveToolTip(siteData.surpriseMe?.postCoinTossInfoText ? siteData.surpriseMe.postCoinTossInfoText : `Click anywhere on the screen when you're ready to leave the well!`);
    } else {
      setActiveToolTip(siteData.surpriseMe?.infoText ? siteData.surpriseMe.infoText : `This land is full of sacred wells. Most are forgotten, but all are portals! Click on the well to toss a coin and receive a surprise!`);
    }
  }, [coinIsTossed, setActiveToolTip, siteData.surpriseMe]);

  const camera = useRef();
  const group = useRef();
  const curve = useRef(new CatmullRomCurve3(
    [0, 0, 0],
    [3, 0, 0],
    false,
    'centripetal',
    [0, -2, 0]
  ));
  const fog = useRef();

  const handleClick = () => {
    if (isReadyToGo === true && isTravellingOut === false) {
      setIsTravellingOut(true);
      setTimeout(() => {
        if (siteData.fragments?.entries) {
          if (siteData.fragments.entries[0]) {
            const randomIndex = Math.floor(Math.random() * (siteData.fragments.entries.length - 0.01));
            props.navigate(`/place-oddity/${siteData.fragments.entries[randomIndex].title_slug}-${siteData.fragments.entries[randomIndex]._created}`);
          }
        }
      }, 1200);
    }
  }

  const tossCoin = (e) => {
    if (camera.current?.position && coinIsTossed === false) {
      setCameraPos({
        unset: true
      });
      setCameraPos({ ...camera.current.position, unset: false });
      setCoinIsTossed(true);
      setTimeout(() => {
        setIsReadyToGo(true);
      }, 5000);
    }
  }

  return (
    // <Suspense fallback={null}>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        style={{
          height: windowHeight + 'px',
          cursor: isReadyToGo === true ? 'pointer' : null
        }}
        transition={{ duration: 0.3 }}
        className="view--surprise"
        onClick={handleClick}
      >
        <SurpriseScene
          {...props}
          coinIsTossed={coinIsTossed}
          coinModel={coinModel}
          isReadyToGo={isReadyToGo}
          setCoinIsTossed={setCoinIsTossed}
          setCoinModel={setCoinModel}
          setIsReadyToGo={setIsReadyToGo}
          isTravellingOut={isTravellingOut}
          setIsTravellingOut={setIsTravellingOut}
          camera={camera}
          group={group}
          curve={curve}
          fog={fog}
          coinMesh={coinMesh}
          cameraPos={cameraPos}
          setCameraPos={setCameraPos}
          tossCoin={tossCoin}
        />
        <A11yAnnouncer />
        <AnimatePresence>
          {
            isTravellingOut === true &&
            <motion.div
              initial={{ opacity: 0, scale: 0 }}
              animate={{ opacity: 1, scale: 12 }}
              exit={{ opacity: 0, scale: 12 }}
              transition={{ duration: 1.2 }}
              className="surprise__glow"
              style={{
                position: 'fixed',
                zIndex: 2,
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                pointerEvents: 'none',
                background: 'radial-gradient(white, transparent)'
              }}
            >
            </motion.div>
          }
        </AnimatePresence>
      </motion.div>
    // </Suspense>
  )
}

export default Surprise;