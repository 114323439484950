import { useRef, useState, useEffect, useMemo } from 'react';
import lerp from '../../utils/lerp';
import { useFrame } from '@react-three/fiber';
import { useGLTF, useTexture } from '@react-three/drei';
import { cockpitUrl } from '../../utils/cockpit';
import ModelMudMaterial from './ModelMudMaterial';
import disp from '../../../assets/disp.png';

const Model = (props) => {
  const { setActiveItemIndex, activeItemIndex, index, item, setActiveToolTip } = props;
  const { object, scale, rotationX, rotationY, rotationZ, positionX, positionZ } = item;
  let modelPrimitive = useGLTF(cockpitUrl + '/' + object);
  const [modelClone, setModelClone] = useState({});
  const modelMesh = useRef();
  const modelMudMesh = useRef();
  const modelMudGroup = useRef();
  const groupRef = useRef();
  const material = useRef();
  const targetMudOpacity = useRef(1);
  const targetYPosition = useRef(0);
  const displacementMap = useTexture(disp);

  useFrame(({ clock }) => {
    groupRef.current.position.y = Math.sin(clock.elapsedTime / 2000) / 8;
    if (activeItemIndex === index) {
      targetMudOpacity.current = 0;
      targetYPosition.current = 2;
    } else {
      targetMudOpacity.current = 1;
      targetYPosition.current = 0;
    }
    if (modelMesh.current && modelMudGroup.current) {
      const yPos = lerp(modelMudGroup.current.position.y, targetYPosition.current, 0.1);
      modelMesh.current.position.y = yPos;
      modelMudGroup.current.position.y = yPos;
    }
    if (material.current) {
      material.current.opacity = lerp(material.current.opacity, targetMudOpacity.current, 0.02);
    }
  });

  useEffect(() => {
    let raf;

    const traverseMesh = () => {
      if (modelPrimitive.scene && material.current) {
        const mudModelClone = modelPrimitive.scene.clone();
        mudModelClone.traverse(
          function (child) {
            if (child.isMesh) {
              if (child.material) {
                child.material = material.current;
              }
            }
          }
        );
        setModelClone(mudModelClone);
      } else {
        raf = requestAnimationFrame(traverseMesh);
      }
    }

    raf = requestAnimationFrame(traverseMesh);

    return () => {
      cancelAnimationFrame(raf);
    }
  }, [activeItemIndex, index, modelPrimitive.scene]);

  const handleHover = () => {
    if (activeItemIndex !== index) {
      setActiveToolTip(item.title)
    }
  }

  const handleBlur = () => {
    if (activeItemIndex !== index) {
      setActiveToolTip('');
    }
  }

	const rotation = useMemo(() => {
		let x = rotationX ?? 0;
		let y = rotationY ?? 0;
		let z = rotationZ ?? 0;

		const multiplier = Math.PI / 180;
		x = x * multiplier;
		y = y * multiplier;
		z = z * multiplier;

		return [ x, y, z ];
	}, [ rotationX, rotationY, rotationZ ]);

  return (
    <group ref={groupRef}>
      {
        // activeItemIndex === index &&
        <mesh
          ref={modelMesh}
          scale={[scale ? scale : 1, scale ? scale : 1, scale ? scale : 1]}
          rotation={ rotation }
          position={[positionX, 0, positionZ]}
          onClick={() => {
            setActiveItemIndex(index);
            setActiveToolTip('');
          }}
          onPointerOver={handleHover}
          onPointerLeave={handleBlur}
        >
          <primitive object={modelPrimitive.scene} />
        </mesh>
      }
      <ModelMudMaterial materialRef={material} displacementMap={displacementMap} />
      {
        modelClone.uuid &&
        <group
          ref={modelMudGroup}
          scale={[1.02, 1.02, 1.02]}
					rotation={ [ rotationX ?? 0, rotationY ?? 0, rotationZ ?? 0 ] }
					position={[positionX, 0, positionZ]}
        >
          <mesh
            ref={modelMudMesh}
            scale={[scale ? scale : 1, scale ? scale : 1, scale ? scale : 1]}
            onClick={() => {
              setActiveItemIndex(index);
            }}
          >
              <primitive object={modelClone} />
            </mesh>
          </group>
      }
    </group>
  )
}

export default Model;