import { useRef, useState, Suspense } from "react";
import { useFrame } from "@react-three/fiber";
import { useTexture } from '@react-three/drei';
import { RepeatWrapping, DoubleSide, Vector2 } from 'three';
import groundSeamless from '../../../assets/information/mud/Ground_wet_003_basecolor.jpg';
import groundNormalSeamless from '../../../assets/information/mud/Ground_wet_003_normal.jpg';
import groundRoughnessSeamless from '../../../assets/information/mud/Ground_wet_003_roughness.jpg';
import groundAoSeamless from '../../../assets/information/mud/Ground_wet_003_ambientOcclusion.jpg';
import groundAlphaSeamless from '../../../assets/information/mud/Ground_wet_003_alpha2.jpg';

const MudMaterial = (props) => {
  const [repeat] = useState(new Vector2(12, 12));

  const groundTexture = useTexture(groundSeamless);
  const groundNormal = useTexture(groundNormalSeamless);
  const groundRoughness = useTexture(groundRoughnessSeamless);
  const groundAo = useTexture(groundAoSeamless);
  const groundAlpha = useTexture(groundAlphaSeamless);

  const map = useRef();
  const normalMap = useRef();
  const roughnessMap = useRef();
  const aoMap = useRef();
  const alphaMap = useRef();

  useFrame(({ mouse, clock }) => {

    let x = mouse.x * 1.2 + clock.elapsedTime * 0.06;
    let y = mouse.y * 1.2 + clock.elapsedTime * 0.06;

    map.current.offset.x = x;
    map.current.offset.y = y;
    normalMap.current.offset.x = x;
    normalMap.current.offset.y = y;
    roughnessMap.current.offset.x = x;
    roughnessMap.current.offset.y = y;
    aoMap.current.offset.x = x;
    aoMap.current.offset.y = y;
    alphaMap.current.offset.x = x;
    alphaMap.current.offset.y = y;
  });

  return (
    <meshPhysicalMaterial
      attach="material"
      color={"white"}
      roughness={0}
      reflectivity={0.9}
      transparent={true}
      side={DoubleSide}
      needsUpdate={true}
    >
      <primitive
        object={groundTexture}
        ref={map}
        attach={`map`}
        wrapS={RepeatWrapping}
        wrapT={RepeatWrapping}
        repeat={repeat}
        needsUpdate={true}
      />
      <primitive
        object={groundNormal}
        ref={normalMap}
        attach={`normalMap`}
        wrapS={RepeatWrapping}
        wrapT={RepeatWrapping}
        repeat={repeat}
      />
      <primitive
        object={groundRoughness}
        ref={roughnessMap}
        attach={`roughnessMap`}
        wrapS={RepeatWrapping}
        wrapT={RepeatWrapping}
        repeat={repeat}
      />
      <primitive
        object={groundAo}
        ref={aoMap}
        attach={`aoMap`}
        wrapS={RepeatWrapping}
        wrapT={RepeatWrapping}
        repeat={repeat}
      />
      <primitive
        object={groundAlpha}
        ref={alphaMap}
        attach={`alphaMap`}
        wrapS={RepeatWrapping}
        wrapT={RepeatWrapping}
        repeat={repeat}
      />
    </meshPhysicalMaterial>
  );
}

const Beach = () => {

  const mesh = useRef();

  return (
    <group>
      <mesh
        scale={[1, 1, 1]}
        rotation={[Math.PI / 2, 0, 0]}
        ref={mesh}
        position={[0, 0, 0]}
        onClick={(e) => {
        }}
      >
        <Suspense fallback={null}>
          <MudMaterial />
        </Suspense>
        <planeBufferGeometry args={[120, 120]} />
      </mesh>
    </group>
  );
};

export default Beach;
export { MudMaterial };