import { AnimatePresence, motion } from 'framer-motion';
import { useState, useRef, Fragment, useEffect } from 'react';
import ReactPlayer from 'react-player';
import { cockpitUrl } from '../utils/cockpit';
import * as _ from 'underscore';
import { durationInSecondsGetHours, durationInSecondsGetMinutes, durationInSecondsGetSeconds } from '../utils/duration.js';

const VideoPlayer = (props) => {

  const { url, noControls, isLooping } = props;
  const [isPlaying, setIsPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [aspectRatio, setAspectRatio] = useState(16 / 9);
	const [ videoDimensions, setVideoDimensions ] = useState({
		width: 0,
		height: 0
	});

	const wrapper = useRef();
  const player = useRef();

  const getCurrentTime = (e) => {
    const timeElapsed = e.playedSeconds;
    setCurrentTime(timeElapsed);
  }

  const getCurrentTimeThrottled = _.throttle(getCurrentTime, 120);

  const getDuration = (e) => {
    const durationInSeconds = e;
    setDuration(durationInSeconds);
  }

  const handleSeek = (e) => {
    player.current.seekTo(e.target.value);
  }

  const handleSeekThrottled = _.throttle(handleSeek, 120);

  const handleInteract = (e) => {
    if (noControls === true && e._reactName === 'onClick') {
      setIsPlaying(!isPlaying);
    }
  }

  const handleFullScreen = (e) => {
    typeof e !== 'undefined' && e.preventDefault();
    if (player.current?.getInternalPlayer()) {
      if (player.current?.getInternalPlayer().requestFullscreen) {
        player.current?.getInternalPlayer().requestFullscreen();
      } else if (player.current?.getInternalPlayer().webkitRequestFullscreen) { /* Safari */
        player.current?.getInternalPlayer().webkitRequestFullscreen();
      } else if (player.current?.getInternalPlayer().msRequestFullscreen) { /* IE11 */
        player.current?.getInternalPlayer().msRequestFullscreen();
      }
    }
  }

	useEffect(() => {
		const video = document.createElement('video');

		const handleGetMetaData = () => {
			setAspectRatio(video.videoWidth / video.videoHeight);
		}

		if (url.indexOf('storage') === 0) {
			video.addEventListener('loadedmetadata', handleGetMetaData);
			video.src = `${cockpitUrl}/${url}`;
		}
	}, [ url ]);

	useEffect(() => {
		let raf;

		const handleResize = () => {
			if (wrapper.current) {
				const wrapperWidth = wrapper.current.offsetWidth;
				const maxHeight = window.innerHeight * 0.5 - 60;
				const containerAspectRatio = wrapperWidth / maxHeight;

				// contain video in container
				if (containerAspectRatio > aspectRatio) {
					// container is wider than video so set height to max height
					setVideoDimensions({
						width: maxHeight * aspectRatio,
						height: maxHeight,
					});
				} else {
					// container is taller than video so set width to max width
					setVideoDimensions({
						width: wrapperWidth,
						height: wrapperWidth / aspectRatio,
					});
				}
			} else {
				raf = requestAnimationFrame(handleResize);
			}
		}

		window.addEventListener('resize', handleResize);
		handleResize();

		return () => {
			window.removeEventListener('resize', handleResize);
			cancelAnimationFrame(raf);
		}
	}, [ aspectRatio, ]);

  if (url) {
    return (
      <Fragment>
				<div
					ref={ wrapper }
					style={ {
						maxHeight: '50vh',
						width: '100%',
					} }
				>
					<div
						className={`video-player${url.indexOf('storage') === 0 || url.indexOf('/storage') === 0 ? ' video-player--video-element' : ''}`}
						onClick={handleInteract}
						onTouchStart={handleInteract}
						onMouseMove={handleInteract}
						style={{
							width: videoDimensions.width + 'px',
							height: videoDimensions.height + 'px',
							margin: '0 auto',
						}}
					>
						<div className="video__wrapper">
							{
								1 === 2 &&
								<button
									className="button--fullscreen"
									aria-label="view fullscreen"
									onClick={handleFullScreen}
								></button>
							}
							<ReactPlayer
								ref={player}
								url={url.indexOf('storage') === 0 ? `${cockpitUrl}/${url}` : url.indexOf('/storage') === 0 ? `${cockpitUrl}${url}` : url}
								className="video-player__player"
								playsinline={true}
								playing={isPlaying}
								volume={1}
								muted={false}
								height={'100%'}
								width={'100%'}
								loop={isLooping}
								onPlay={() => { setIsPlaying(true) }}
								onPause={() => { setIsPlaying(false) }}
								onProgress={(e) => {
									getCurrentTimeThrottled(e)
								}}
								onSeek={(e) => {
									getCurrentTimeThrottled(e)
								}}
								onDuration={(e) => {
									getDuration(e)
								}}
							/>
						</div>
					</div>
					<div className={`video-player__controls player__controls`}>
						<AnimatePresence>
							{
								noControls !== true &&
								<motion.div
									initial={{ opacity: 0 }}
									animate={{ opacity: 1 }}
									exit={{ opacity: 0 }}
									transition={{ duration: .2 }}
									className="video-player__controls__inner player__controls__inner"
								>
									<div className="video-player__time__wrapper player__time__wrapper">
										<p className="video-player__time player__time video-player__time--elapsed player__time--elapsed">
											{durationInSecondsGetHours(currentTime)} : {durationInSecondsGetMinutes(currentTime)} : {durationInSecondsGetSeconds(currentTime)}
										</p>
										<button
											className={`video-player__button--play-pause player__button--play-pause ${isPlaying === false ? 'play' : 'pause'}`}
											onClick={(e) => {
												typeof e !== 'undefined' && e.preventDefault();
												setIsPlaying(!isPlaying);
											}}
										/>
										<div className="video-player__input--time__wrapper player__input--time__wrapper">
											<input
												type="range"
												className="video-player__input--time player__input--time"
												name="time"
												max={duration}
												value={currentTime}
												onChange={(e) => {
													setIsPlaying(false);
													setCurrentTime(e.target.value);
													handleSeekThrottled(e);
												}}
												onMouseDown={() => {
													setIsPlaying(false);
												}}
												onMouseUp={() => {
													setIsPlaying(true);
												}}
											/>
										</div>
									</div>
								</motion.div>
							}
						</AnimatePresence>
					</div>
				</div>
      </Fragment>
    )
  } else {
    return null;
  }
}

export default VideoPlayer;