import { useState, useEffect } from 'react';
import IconSelection from './IconSelection';
import ItemContentEditor from './ItemContentEditor';
import ItemLayers from './ItemLayers';
import ItemLocations from './ItemLocations';
import slugify from 'slugify';
import ItemEditorStyles from './ItemEditorStyles';
import { cockpitUrl } from '../../utils/cockpit';
import PostcodeFinder from './PostcodeFinder';

const ItemEditor = (props) => {

  const {
    siteData, currentItem, setCurrentItem, originalItem, setBorderImageSource,
    setSavePromptIsActive, imagesByContentRow, setImagesByContentRow
  } = props;

  const [layers, setLayers] = useState([]);
  const [locations, setLocations] = useState([]);
  const [iconsGridIsVisible, setIconsGridIsVisible] = useState(false);

  useEffect(() => {
    if (currentItem.borderStyle === 'icon' && currentItem.icon?.path) {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      canvas.width = 32 * 3;
      canvas.height = 32 * 3;
      const img = document.createElement('img');
      img.crossOrigin = 'anonymous';
      img.onload = () => {
        for (let i = 0; i < 3; i++) {
          for (let j = 0; j < 3; j++) {
            ctx.drawImage(img, i * 32, j * 32);
          }
        }
        setBorderImageSource(canvas.toDataURL());
      }
      img.src = cockpitUrl + currentItem.icon?.path;
    } else {
      setBorderImageSource('');
    }
  }, [currentItem.borderStyle, currentItem.icon, setBorderImageSource]);

  const handleCancel = (e) => {
    e.preventDefault();
    setCurrentItem(originalItem);
  }

  const handleTitleInputChange = (e) => {
    setCurrentItem({
      ...currentItem,
      title: e.target.value,
      title_slug: slugify(e.target.value + '-' + new Date().getTime())
    });
  }

  const handleSelectIcon = (path) => {
    setCurrentItem({
      ...currentItem,
      icon: {
        path: path
      }
    });
  }

  const handleSubmitForm = (e) => {
    typeof e !== 'undefined' && e.preventDefault();
    setSavePromptIsActive(true);
  }

  return (
    <form
      className={`editor__item`}
      onSubmit={handleSubmitForm}
    >
      <div className="editor__item__inner">
        <header className="row editor__item__header">
          <div className="col-xxs-12 editor__item__header__intro">
            <p>You can add your own place oddities to the map here! These can take the form of text, images, video (YouTube and Vimeo links only—no direct uploads!) and SoundCloud/Mixcloud links.</p>
            <p>You can also customise the look of your place oddity with icons, colours and borders!</p>
          </div>
          <div className="col-xxs-12 col-sm-7 editor__item__title__wrapper">
            <label htmlFor="title" className="editor__item__title editor__item__content__heading">Give your place oddity a name!</label>
            <div className="editor__item__title__input__wrapper">
              <input type="text" name="title" onChange={handleTitleInputChange} value={currentItem.title} />
            </div>
          </div>
        </header>
        <div className="editor__item__content">
          <div className="col-xxs-12">
            <PostcodeFinder {...props} />
          </div>
          <div className="col-xxs-12">
            <label className="editor__item__content__heading">Choose an icon for your place oddity. This will show up on the map on the homepage!</label>
            <button
              className="b04 button"
              onClick={(e) => {
								e.preventDefault();
								setIconsGridIsVisible(!iconsGridIsVisible);
							}}
            >{iconsGridIsVisible === true ? 'cancel' : 'Select icon'}</button>
            {
              iconsGridIsVisible === true &&
              <IconSelection
                handleSelectIcon={handleSelectIcon}
                activePath={currentItem.icon ? currentItem.icon.path : ''}
              />
            }
          </div>
          <ItemContentEditor
            imagesByContentRow={imagesByContentRow}
            setImagesByContentRow={setImagesByContentRow}
            currentItem={currentItem}
            setCurrentItem={setCurrentItem}
            handleSubmitForm={handleSubmitForm}
          />
          <div className="col-xxs-12">
            <ItemEditorStyles {...props} />
          </div>
          <div className="col-xxs-12">
            <div className="row">
              <div className="col-xxs-12">
                <p>Select as many categories and locations as you want for your place oddity, using the checkboxes below. This will help it show up when users search and filter the site.</p>
              </div>
              <div className="col-xxs-12 col-sm-6">
                <ItemLayers currentItem={currentItem} setCurrentItem={setCurrentItem} layers={layers} siteData={siteData} setLayers={setLayers} />
              </div>
              <div className="col-xxs-12 col-sm-6">
                <ItemLocations currentItem={currentItem} setCurrentItem={setCurrentItem} locations={locations} siteData={siteData} setLocations={setLocations} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer className="editor__footer">
        <div className="editor__footer__buttons col-xxs-12">
          <input className="button b04 auto-width" type="submit" name="submit" value="Submit!" onClick={handleSubmitForm} />
          <button
            className="button b04 auto-width"
            onClick={handleCancel}
          >Start again</button>
        </div>
      </footer>
    </form>
  )
}

export default ItemEditor;