import { useState, useEffect, useMemo } from 'react';
import { motion } from 'framer-motion';
import { Link, useLocation } from 'react-router-dom';
import { cockpitUrl } from '../utils/cockpit';
import Font from '../components/fragments/Font';
import FragmentContent from '../components/fragments/FragmentContent';

const Fragment = (props) => {

  const { windowDimensions, setCurrentItem, item, returnPath } = props;
  const { windowWidth, windowHeight } = windowDimensions;
  const [borderImageSource, setBorderImageSource] = useState('');
  const [borderWidth, setBorderWidth] = useState(12);

	useEffect(() => {
		const icon = item.icon;
		if (icon?.path?.length > 0) {
			const favicon = document.querySelector('link[rel="icon"]');
			if (favicon) {
				favicon.href = cockpitUrl + icon.path;
			}
		}
	}, [ item ]);

	const location = useLocation();

  useEffect(() => {
    if (item.borderStyle === 'icon' && item.icon?.path) {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      canvas.width = 32 * 3;
      canvas.height = 32 * 3;
      const img = document.createElement('img');
      img.crossOrigin = 'anonymous';
      img.onload = () => {
        for (let i = 0; i < 3; i++) {
          for (let j = 0; j < 3; j++) {
            ctx.drawImage(img, i * 32, j * 32);
          }
        }
        setBorderImageSource(canvas.toDataURL());
      }
      img.src = cockpitUrl + item.icon?.path;
    } else {
      setBorderImageSource('');
    }
    if (item.borderWidth && item.borderWidth !== '') {
      if (item.borderStyle === 'icon') {
        setBorderWidth(32);
      } else {
        setBorderWidth(parseFloat(item.borderWidth));
      }
    } else {
      if (item.borderStyle === 'icon') {
        setBorderWidth(32);
      } else {
        setBorderWidth(1);
      }
    }
  }, [item.borderStyle, item.icon, item.borderWidth]);

	const leftAmount = useMemo(() => {
		if (location.pathname === '/archive') {
			return windowWidth < 768 ? windowWidth * 0.5 : (windowWidth - 240) * 0.5 + 240;
		} else {
			return windowWidth * 0.5;
		}
	}, [ windowWidth, location.pathname, ]);

  return (
		<motion.div
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			exit={{ opacity: 0 }}
			transition={{ duration: 0.25 }}
			className='fragment__container'
		>
			<Link
				to={  returnPath ? returnPath : '/' }
				className='fragment__close--big-link'
				onClick={(e) => {
					setCurrentItem({});
				}}
			/>
			<div
				className={`fragment${item.borderStyle ? ` fragment--border--${item.borderStyle}` : ''}`}
				style={{
					maxWidth: item.borderStyle === 'icon' && borderWidth >= 12 ? windowWidth - borderWidth * 2 + 'px' : null,
					maxHeight: item.borderStyle === 'icon' && borderWidth >= 12 ?
						windowWidth > 767 ? windowHeight - (180 - borderWidth * 2) + 'px' :
							windowHeight - (60 + borderWidth * 2) + 'px'
						:
						windowWidth > 767 ? windowHeight - 180 + 'px' : windowHeight - 80 + 'px',
					top: '50%',
					left: leftAmount + 'px',
					zIndex: 1000,
					height: windowWidth < 768 ? 'auto' : null,
					transform: 'translate(-50%, -50%)',
					backgroundColor: item.backgroundColor ? item.backgroundColor : null,
					borderColor: item.borderColor ? item.borderColor : null,
					borderWidth: item.borderStyle !== 'icon' && borderWidth ? borderWidth + 'px' : item.borderStyle !== 'icon' ? '10px': '1px',
					borderStyle: item.borderStyle && item.borderStyle !== 'icon' ? item.borderStyle : item.borderStyle !== 'icon' ? null : null,
					borderImageSource: item.borderStyle === 'icon' && item.icon ? `url(${borderImageSource})` : null
				}}
			>
				<Font item={item} className={`fragment__inner--${item._id}`} />
				<div className={`fragment__inner fragment__inner--${item._id}`}
					style={{
						maxHeight: windowWidth > 767 ? windowHeight - 180 + 'px' : item.borderStyle === 'icon' && borderWidth >= 12 ?
							windowHeight - (60 + borderWidth * 2) + 'px'
							:
							windowHeight - 80 + 'px',
					}}
				>
				<header className="fragment__header col-xxs-12">
					<h2 className="fragment__title">{item.title}</h2>
					<Link
						to={returnPath ? returnPath : '/'}
						className="button auto-width fragment__close b04"
						onClick={(e) => {
							setCurrentItem({});
						}}
					>
						<div className='button__inner'>
							close
						</div>
					</Link>
				</header>
					<div className="fragment__content">
						<FragmentContent item={item} />
					</div>
				</div>
			</div>
		</motion.div>
  )
}

export default Fragment;