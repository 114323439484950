const ItemLayers = (props) => {

  const { currentItem, setCurrentItem, siteData } = props;

  const handleLayerSelect = (e, layer) => {
    const currentLayers = Array.isArray(currentItem.layers) === true ? [...currentItem.layers] : [];
    if (e.target.checked === true) {
      currentLayers.push({
        _id: layer._id,
        display: layer.title,
        link: 'layers'
      });
      setCurrentItem({
        ...currentItem,
        layers: [...currentLayers]
      });
    } else {
      const index = currentItem.layers.indexOf(layer._id);
      const newLayers = [...currentItem.layers];
      newLayers.splice(index, 1);
      const newCurrentLayers = [...currentLayers];
      newCurrentLayers.splice(index, 1);
      setCurrentItem({
        ...currentItem,
        layers: newCurrentLayers
      });
    }
  }

  return (
    <div className="editor__item__categories__wrapper">
      <ul className="editor__item__list--checkboxes editor__item__categories__list">
        {
          siteData.layers &&
          siteData.layers.entries &&
          siteData.layers.entries.map(
            (layer, i) => (
              <li className={`editor__item__category${currentItem.layers ? currentItem.layers.some(items => items._id === layer._id) ? ' checked' : '' : ''}`} key={i}>
                <label htmlFor={layer._id} className="editor__item__category__label">
                  <input
                    type="checkbox"
                    name="layers"
                    value={layer._id}
                    onChange={(e) => {
                      handleLayerSelect(e, layer);
                    }}
                    className="editor__item__category__checkbox"
                    checked={Array.isArray(currentItem.layers) === true ?
                      currentItem.layers[0] ?
                        currentItem.layers.some(items => items._id === layer._id)
                        :
                        false
                      :
                      false
                    }
                  />
                  <span className="editor__item__category__title editor__item__list--checkboxes__item__title">{layer.title}</span>
                </label>
              </li>
            )
          )
        }
      </ul>
    </div>
  )
}

export default ItemLayers;