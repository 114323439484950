import { motion } from 'framer-motion';
import parse from 'html-react-parser';
import MicroficheScene from './MicroficheScene';
import MicroficheScreenContents from './MicroficheScreenContents';
import MicroficheResponsiveScreen from './MicroficheResponsiveScreen';
import ArchiveNav from './ArchiveNav';
import ArchiveFragmentsList from './ArchiveFragmentsList';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const ArchiveContents = (props) => {

  const { windowWidth, windowHeight, setMenuIsActive, isArchiveCloseupView, setIsArchiveCloseupView, view, setView, siteData, archiveExplainerIsVisible, setArchiveExplainerIsVisible } = props;
  const { archive } = siteData;
  const { archiveExplainer, listViewInfo, michroficheDetailInfo } = archive;
	const location = useLocation();
	const [ initialisedForList, setInitialisedForList ] = useState(false);
	const [ initialisedForMicrofiche, setInitialisedForMicrofiche ] = useState(false);
	const [ initialisedForMicroficheScreen, setInitialisedForMicroficheScreen ] = useState(false);

	const [ archiveIsOpen, setArchiveIsOpen ] = useState(false);
	const [ activeItem, setActiveItem ] = useState(null);

	useEffect(() => {
		if (location.pathname === '/archive') {
			setArchiveIsOpen(true);
		} else {
			if (!archiveIsOpen) {
				const itemSlug = location.pathname.split('/').pop();
				let activeItem = siteData?.fragments?.entries?.find((item) => (item.title_slug + '-' + item._created) === itemSlug);
				setActiveItem(activeItem);
				setView('list');
				setIsArchiveCloseupView(true);
			}
		}
	}, [ location.pathname, siteData?.fragments?.entries, archiveIsOpen, setArchiveIsOpen, setIsArchiveCloseupView, setView, ]);
  
  useEffect(() => {
		if (location.pathname === '/archive') {
			if (view === 'list' && initialisedForList === false) {
				setInitialisedForList(true);
				setArchiveExplainerIsVisible(true);
			} else if (isArchiveCloseupView === false && view === 'microfiche' && initialisedForMicrofiche === false) {
				setInitialisedForMicrofiche(true);
				setArchiveExplainerIsVisible(true);
			} else if (isArchiveCloseupView === true && view === 'microfiche' && initialisedForMicroficheScreen === false) {
				setInitialisedForMicroficheScreen(true);
				setArchiveExplainerIsVisible(true);
			}
		} else {
			setArchiveExplainerIsVisible(false);
			if (view === 'list') {
				setInitialisedForList(true);
			} else if (view === 'microfiche') {
				setInitialisedForMicrofiche(true);
			} else if (isArchiveCloseupView === true) {
				setInitialisedForMicroficheScreen(true);
			}
		}
  }, [ location?.pathname, view, isArchiveCloseupView, setArchiveExplainerIsVisible, initialisedForList, initialisedForMicrofiche, initialisedForMicroficheScreen, ]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="view view--archive"
      style={{
        width: windowWidth + 'px',
        height: windowHeight + 'px'
      }}
      onClick={() => setMenuIsActive(false)}
    >
      {
        view === 'list' &&
        <ArchiveFragmentsList { ...props } activeItem={ activeItem } setActiveItem={ setActiveItem } />
      }
      {
        view === 'microfiche' &&
        <MicroficheScene { ...props } />
      }
      {
        view === 'microfiche' &&
        <div className="microfiche__screen__contents__wrapper">
          <MicroficheScreenContents {...props} />
        </div>
      }
      {
        <ArchiveNav
          {...props}
          closeButton={ true }
          setArchiveExplainerIsVisible={setArchiveExplainerIsVisible}
        />
      }
      {
        isArchiveCloseupView === true && view === 'microfiche' &&
        <div className="microfiche__screen__wrapper">
          <MicroficheResponsiveScreen
            {...props}
          />
        </div>
      }
      {
        archiveExplainerIsVisible === true &&
        <div className="archive-explainer">
          <div className="archive-explainer__inner">
            <div className="archive-explainer__content">
              {
                isArchiveCloseupView === false && view !== 'list' &&
                <div>
                  {
										archiveExplainer ?
											parse(archiveExplainer)
                        :
											<p className='archive-explainer__content__p'>
												The Archive contains a selection of fragments from the collection.
													Click the microfiche to look through!
													
													You can also click 'list' on the left hand side bar if you'd prefer to view the fragments in a list.
											</p>
                  }
								</div>
              }
              {
                view === 'list' &&
                <div>
                  {
                    listViewInfo ?
                    parse(listViewInfo)
                    :
                    <p className='archive-explainer__content__p'>
                      Click on an item on the list to view it.
                    </p>
                  }
                </div>
              }
              {
                view === 'microfiche' && isArchiveCloseupView === true &&
                <div>
                  {
                    michroficheDetailInfo ?
                      parse(michroficheDetailInfo)
                      :
                      <p className='archive-explainer__content__p'>
                        Click on an item on the microfiche screen to view it.
                          
                        Use the + and - buttons next to the magnifying glass to zoom in and out.
                        You can also zoom in by double-clicking.
                            
                        Drag/scroll/swipe the microfiche screen to move the view around.

                        Use the sort and filter options in the left-hand side bar to sort through the selection on the screen.
                      </p>
                  }
                </div>
              }
            </div>
            <button
              className="archive-explainer__close button--plain b04"
              onClick={() => setArchiveExplainerIsVisible(false)}
            >
							<div className='archive-explainer__close__inner'>
								close
							</div>	
						</button>
          </div>
        </div>
      }
    </motion.div>
  )
}

export default ArchiveContents;