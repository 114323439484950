import { Fragment } from 'react';
import { useRef, useState, useEffect } from 'react'
import { Outlet } from 'react-router-dom';
import ArchiveContent from '../components/archive/ArchiveContent';

const Archive = (props) => {

  const { siteData, isArchiveCloseupView, setIsArchiveCloseupView } = props;
  const { fragments } = siteData;

  const [allItems, setAllItems] = useState([]);
  const [view, setView] = useState('microfiche');
  const [items, setItems] = useState([]);
  const [allListItems, setAllListItems] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(1200);
  const [zoomLevel, setZoomLevel] = useState(0);
  const [archiveNavIsOpen, setArchiveNavIsOpen] = useState(false);
  const screenContents = useRef();
  const [selectedLayer, setSelectedLayer] = useState({});
  const [selectedLocation, setSelectedLocation] = useState({});
  const [selectedPageNumber, setSelectedPageNumber] = useState(0);
  const [pagesLength, setPagesLength] = useState(0);
  const [isAnimatingZoom, setIsAnimatingZoom] = useState(false);
  const [sortBy, setSortBy] = useState('chronologically');

  useEffect(() => {
    if (fragments?.entries) {

      const itemsArray = [];
      const allListItemsArray = [];

      for (let entry of fragments.entries) {
        if (entry.content) {
          for (let contentItem of entry.content) {
            const newContentItem = {
              layers: [...entry.layers],
              locations: [...entry.locations],
              location: { ...entry.location },
              title: entry.title,
              _created: entry._created,
              _id: entry._id,
              content: { ...contentItem }
            }
            itemsArray.push(newContentItem);
          }
          allListItemsArray.push(entry);
        }
      }
      setAllListItems(allListItemsArray);
      setAllItems(itemsArray);
    }
  }, [fragments]);



  useEffect(() => {
    if (selectedLayer && selectedLocation) {
      setSelectedPageNumber(0);
    }
  },[selectedLayer, selectedLocation]);

  useEffect(() => {
    if (allItems[0] && allListItems[0]) {

      let itemsPerPageNumber = itemsPerPage;
      if (view === 'microfiche') {
        itemsPerPageNumber = 12 * 3;
      } else {
        itemsPerPageNumber = allListItems.length;
      }

      const newItemsArray = [];
      let allItemsArray = view === 'microfiche' ? allItems : allListItems;

      for (let item of allItemsArray) {
        let matchesLayer = true;
        let matchesLocation = true;

        if (selectedLayer._id) {
          matchesLayer = false;
          if (item.layers) {
            for (let layer of item.layers) {
              if (layer._id === selectedLayer._id) {
                matchesLayer = true;
              }
            }
          }
        }

        if (selectedLocation._id) {
          matchesLocation = false;
          if (item.locations) {
            for (let location of item.locations) {
              if (location._id === selectedLocation._id) {
                matchesLocation = true;
              }
            }
          }
        }

        if (matchesLayer === true && matchesLocation === true) {
          newItemsArray.push({...item });
        }
      }

      const numberOfPages = ((newItemsArray.length - (newItemsArray.length % itemsPerPageNumber)) / itemsPerPageNumber + 1);
      setPagesLength(numberOfPages);

      let newShownItemsArray = [];
      for (let i = selectedPageNumber * itemsPerPageNumber; i < Math.min(newItemsArray.length, (selectedPageNumber + 1) * itemsPerPageNumber); i++) {
        newShownItemsArray.push({...newItemsArray[i]});
      }

      if (sortBy === 'chronologically') {
        newShownItemsArray = newShownItemsArray.sort((a, b) => (a._created > b._created) ? -1 : 1);
      } else if (sortBy === 'reverse chronologically') {
        newShownItemsArray = newShownItemsArray.sort((a, b) => (a._created > b._created) ? 1 : -1);
      } else if (sortBy === 'alphabetically') {
        newShownItemsArray = newShownItemsArray.sort((a, b) => (a.title > b.title) ? 1 : -1);
      } else {
        newShownItemsArray = newShownItemsArray.sort((a, b) => (a.title > b.title) ? -1 : 1);
      }

      setItems(newShownItemsArray);
    }
  }, [allItems, selectedLayer, selectedLocation, selectedPageNumber, sortBy, view, itemsPerPage, allListItems]);

  return (
    <Fragment>
      <ArchiveContent
        {...props}
        zoomLevel={zoomLevel}
        setZoomLevel={setZoomLevel}
        screenContents={screenContents}
        selectedLayer={selectedLayer}
        selectedLocation={selectedLocation}
        selectedPageNumber={selectedPageNumber}
        setSelectedPageNumber={setSelectedPageNumber}
        pagesLength={pagesLength}
        setPagesLength={setPagesLength}
        items={items}
        allListItems={allListItems}
        isArchiveCloseupView={isArchiveCloseupView}
        setIsArchiveCloseupView={setIsArchiveCloseupView}
        setSelectedLayer={setSelectedLayer}
        setSelectedLocation={setSelectedLocation}
        isAnimatingZoom={isAnimatingZoom}
        setIsAnimatingZoom={setIsAnimatingZoom}
        view={view}
        setView={setView}
        sortBy={sortBy}
        setSortBy={setSortBy}
        itemsPerPage={itemsPerPage}
        setItemsPerPage={setItemsPerPage}
        archiveNavIsOpen={archiveNavIsOpen}
        setArchiveNavIsOpen={setArchiveNavIsOpen}
      />
      <Outlet />
    </Fragment>
  )
}

export default Archive;