import { OrthographicCamera } from '@react-three/drei';
import LavaBall from './LavaBall.js';

const Camera = (props) => {
  const { camera, sea, pathname } = props;

  return (
    <OrthographicCamera
      makeDefault
      ref={camera}
      position={[0, 0, 120]}
      rotation={[0, 0, 0]}
      near={0.1}
      far={1000000}
    >
      {
        sea !== false &&
        <LavaBall camera={camera} pathname={pathname} />
      }
    </OrthographicCamera>
  )
}

export default Camera;