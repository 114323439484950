import { useEffect } from 'react';
import LazyloadImage from '../LazyloadImage';

const LandingPageLayersList = (props) => {

  const { siteData, selectedLayer, setSelectedLayer, windowDimensions, layersListIsExpanded, setLayersListIsExpanded, locationsListIsExpanded, setMenuIsActive } = props;
  const { windowWidth, windowHeight } = windowDimensions;

  useEffect(() => {
    if (locationsListIsExpanded === true) {
      setLayersListIsExpanded(false);
    }
  }, [locationsListIsExpanded, setLayersListIsExpanded]);

  return (
    <nav
      className={`nav--panel nav--layers${layersListIsExpanded === false ? ' condensed' : ''}${selectedLayer._id ? ' layer-is-selected' : ''}`}
      onClick={() => setMenuIsActive(false)}
      style={{
        height: windowWidth < 1024 ?
          layersListIsExpanded === true ? windowHeight - 96 - 60 + 'px' : null
        :
          layersListIsExpanded === true ? windowHeight - 120 - 60 + 'px' : null
      }}
    >
      <div className="nav--landing-page__nav__inner">
        <button
          className="nav--panel__header button--plain"
          aria-label={layersListIsExpanded === true ? `Close layers panel` : `Open layers panel`}
          onClick={() => {
            setLayersListIsExpanded(!layersListIsExpanded);
          }}
        >
          <h2 className="nav--panel__heading">Layers</h2>
          <div className="button--nav-open-close__wrapper">
            <div
              className={`button--nav-open-close button--plain ${layersListIsExpanded === true ? 'open' : 'closed'}`}
            ></div>
          </div>
        </button>
        <ul className="landing-page__nav__list">
          {
            siteData.layers &&
            siteData.layers.entries &&
            siteData.layers.entries.map(
              (item, i) => (
                <li className={`landing-page__nav__list__item${selectedLayer._id === item._id ? ' selected' : ''}`} key={i}>
                  <button
                    className="landing-page__nav__list__item__button button--plain"
                    onClick={(e) => {
                      e.preventDefault();
                      if (selectedLayer._id !== item._id) {
                        setSelectedLayer(item);
                      } else {
                        setSelectedLayer({});
                      }
                    }}
                  >
                    <div className="landing-page__nav__list__item__icon__wrapper">
                      {
                        item.icon &&
                        item.icon.path && item.icon.path !== '' &&
                        <LazyloadImage image={item.icon} w={32} h={32} />
                      }
                    </div>
                    <p className="landing-page__nav__list__item__title">{item.title}</p>
                  </button>
                </li>
              )
            )
          }
        </ul>
      </div>
    </nav>
  )
}

export default LandingPageLayersList;