import { useState } from 'react';

const ItemContentEditorImage = (props) => {
  const { index, currentItem, setCurrentItem } = props;
  const [imagePreviewPath, setImagePreviewPath] = useState('');

  const processImage = (event) => {
    const img = document.createElement('img');

    const handleImageLoad = () => {
      const canvas = document.createElement('canvas');
      canvas.width = 2000;
      canvas.height = Math.floor(2000 / img.naturalWidth * img.naturalHeight);
      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
      
      const dataURL = canvas.toDataURL('image/jpeg', 0.3);
      setImagePreviewPath(dataURL);
      
      canvas.toBlob((blob) => {
        const file = new File([blob], `image-at-${new Date().getTime()}.jpg`, { type: "image/jpeg" });

        const newContent = [...currentItem.content];
        newContent[index].value = file;

        setCurrentItem({
          ...currentItem,
          content: [
            ...newContent
          ]
        })
      }, 'image/jpeg', 0.3);
    }

    img.addEventListener('load', handleImageLoad);

    if (event) {
      if (event.target?.files && event.target.files.length > 0) {
        img.src = URL.createObjectURL(event.target.files[0]);
      }
    }

    return () => {
      img.removeEventListener('load', handleImageLoad);
    }
  }

  return (
    <div className="editor__content__item__content editor__content__item__content--image">
      {
        imagePreviewPath !== '' &&
        <img className="editor__content__item__content--image__preview" src={imagePreviewPath} alt="a preview of the file you uploaded" />
      }
      <input
        className="editor__content__item__input--file"
        type="file"
        name="file"
        accept="image/png, image/jpg, image/jpeg, image/gif"
        onChange={(e) => {
          if (e.target.files[0]) {
            processImage(e);
          } else {
            const newContent = [...currentItem.content];
            newContent[index].value = null;
            setImagePreviewPath('');
            setCurrentItem({
              ...currentItem,
              content: [
                ...newContent
              ]
            })
          }
        }}
      />
    </div>
  )
}

export default ItemContentEditorImage;