import { useRef, } from 'react';
import { useFrame } from '@react-three/fiber';
import { A11y } from '@react-three/a11y';
import Model from './Model';

const Models = (props) => {
  const group = useRef();
  const { information } = props;

  useFrame(({ mouse }) => {
    let x = -mouse.x * 0.3;
    let y = -mouse.y * 0.3;

    group.current.position.x = x;
    group.current.position.z = y;
  });

  return (
    <group ref={group}>
      {
        information &&
        information[0] &&
        information.map(
          (item, i) => (
            item.object &&
						<group key={ i }>
              <A11y role="button" description="Click to see some information!">
                <Model item={item} {...props} index={i} />
              </A11y>
						</group>
          )
        )
      }
    </group>
  )
}

export default Models;