import { useTexture } from '@react-three/drei';
import { useRef, useState, useEffect } from 'react';
import * as THREE from 'three';
import tilesNormal from '../../../assets/tiles.jpg';
import tilesMap from '../../../assets/marble_seamless_texture_6700.jpg';

const GroundAlphaMap = (props) => {

  const { alphaMapImage } = props;
  const alphaMap = useTexture(alphaMapImage);

  return (
    <primitive attach="alphaMap" object={alphaMap} />
  )
}

const Ground = () => {
  const material = useRef();
  const normal = useTexture(tilesNormal);
  const map = useTexture(tilesMap);
  const [alphaMapImage, setAlphaMapImage] = useState('');

  useEffect(() => {
    const canvas = document.createElement('canvas');
    canvas.width = 256;
    canvas.height = 256;
    const ctx = canvas.getContext('2d');
    const grd = ctx.createRadialGradient(128, 128, 5, 128, 128, 160);
    grd.addColorStop(0.6, "white");
    grd.addColorStop(0.8, "black");
    ctx.fillStyle = grd;
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    ctx.beginPath();
    ctx.arc(128, 128, 16, 0, 2 * Math.PI);
    ctx.fillStyle = 'black';
    ctx.fill();
    ctx.closePath();
    ctx.fillRect(128, 128, 90, 0, 2 * Math.PI);
    canvas.setAttribute('style', 'position:absolute; z-index: 999999; top: 0; left: 0;');
    const alphaSrc = canvas.toDataURL('image/jpeg');
    setAlphaMapImage(alphaSrc);
  }, []);

  return (
    <mesh
      position={[0, -2, -0.06]}
      rotation={[Math.PI / 2, 0, 0]}
    >
      <meshPhysicalMaterial
        ref={material}
        side={THREE.DoubleSide}
        metalness={0.8}
        needsUpdate={true}
        transparent={true}
      >
        <primitive attach="normalMap" object={normal} />
        <primitive attach="map" object={map} />
        {
          alphaMapImage !== '' &&
          <GroundAlphaMap alphaMapImage={alphaMapImage} />
        }
        </meshPhysicalMaterial>
      <planeBufferGeometry args={[16, 16]} />
    </mesh>
  )
}

export default Ground;