const ItemLocations = (props) => {

  const { currentItem, setCurrentItem, siteData } = props;

  const handleLocationSelect = (e, location) => {
    const currentLocations = Array.isArray(currentItem.locations) === true ? [...currentItem.locations] : [];
    if (e.target.checked === true) {
      currentLocations.push({
        _id: location._id,
        display: location.title,
        link: 'locations'
      });
      setCurrentItem({
        ...currentItem,
        locations: [...currentLocations]
      });
    } else {
      const index = currentItem.locations.indexOf(location._id);
      const newLocations = [...currentItem.locations];
      newLocations.splice(index, 1);
      const newCurrentLocations = [...currentLocations];
      newCurrentLocations.splice(index, 1);
      setCurrentItem({
        ...currentItem,
        locations: newCurrentLocations
      });
    }
  }

  return (
    <div className="editor__item__categories__wrapper">
      <ul className="editor__item__list--checkboxes editor__item__categories__list">
        {
          siteData.locations &&
          siteData.locations.entries &&
          siteData.locations.entries.map(
            (location, i) => (
              <li
                className={`editor__item__category${currentItem.locations ? currentItem.locations.some(items => items._id === location._id) ? ' checked' : '' : ''}`}
                key={i}
              >
                <label htmlFor={location._id} className="editor__item__category__label">
                  <input
                    type="checkbox"
                    name="locations"
                    value={location._id}
                    onChange={(e) => {
                      handleLocationSelect(e, location);
                    }}
                    className="editor__item__category__checkbox"
                    checked={Array.isArray(currentItem.locations) === true ?
                      currentItem.locations[0] ?
                        currentItem.locations.some(item => item._id === location._id)
                        :
                        false
                      :
                      false
                    }
                  />
                  <span className="editor__item__category__title editor__item__list--checkboxes__item__title">{location.title}</span>
                </label>
              </li>
            )
          )
        }
      </ul>
    </div>
  )
}

export default ItemLocations;