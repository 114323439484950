import { useState, useEffect } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import InformationSection from '../components/information/InformationSection';
import Scene from '../components/information/Scene';
import { A11yAnnouncer } from '@react-three/a11y';

const Information = (props) => {

  const { windowDimensions, siteData, setActiveToolTip } = props;
  const { informationPageToolTips, information } = siteData;
  const { windowHeight } = windowDimensions;
  const [activeItemIndex, setActiveItemIndex] = useState(-1);

	const [ sceneIsLoaded, setSceneIsLoaded ] = useState(false);

  useEffect(() => {
		if (sceneIsLoaded) {
			setActiveToolTip(`Click on the items to retrieve them from the muddy foreshore`);
		}
  }, [ sceneIsLoaded, setActiveToolTip ]);

  return (
		<motion.div
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			exit={{ opacity: 0 }}
			className="view--information"
			style={{
				height: windowHeight + 'px',
					background: 'black',
			}}
		>
			<AnimatePresence>
				{
					!sceneIsLoaded &&
					<motion.div
						key="information"
						initial={{ opacity: 0 }}
						animate={{ opacity: 1 }}
						exit={{ opacity: 0 }}
						className="information--loading"
						style={{
							position: 'absolute',
							top: 0,
							left: 0,
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							width: '100%',
							height: '100%',
						}}
					>
						<p style={{ color: 'white', textAlign: 'center' }}>{ informationPageToolTips?.loadingText ? informationPageToolTips?.loadingText : '' }</p>
					</motion.div>
				}
			</AnimatePresence>
			<div
				className=""
				style={{
					width: '100%',
					height: '100%',
					pointerEvents: sceneIsLoaded ? 'auto' : 'none',
					opacity: sceneIsLoaded ? 1 : 0,
					transition: 'opacity 0.5s'
				} }
			>
				{
					information.entries &&
					<Scene {...props} activeItemIndex={activeItemIndex} setActiveItemIndex={setActiveItemIndex} information={information.entries} { ...{ sceneIsLoaded, setSceneIsLoaded, } } />
				}
			</div>
			<A11yAnnouncer />
			{
				activeItemIndex !== -1 && information?.entries && information?.entries[activeItemIndex] &&
				<InformationSection activeItemIndex={activeItemIndex} setActiveItemIndex={setActiveItemIndex} {...props} information={information.entries} />
			}
		</motion.div>
  )
}

export default Information;