import { Suspense, useEffect, useRef } from 'react';
import { Canvas } from '@react-three/fiber';
import Beach from './Beach';
import Mud from './Mud';
import Lights from './Lights';
import Models from './Models';
import Camera from './Camera';

const SceneContent = (props) => {
	const { setSceneIsLoaded, information } = props;
	const camera = useRef();

	useEffect(() => {
		setSceneIsLoaded(true);
	}, [setSceneIsLoaded]);

	return (
			<>
				<Camera camera={camera} />
				<Lights />
				<Beach />
				<Mud />
				<Models {...props} information={information} />
			</>
	)
}

const Scene = (props) => {

  return (
    <Canvas>
			<Suspense fallback={null}>
				<SceneContent {...props} />
			</Suspense>
    </Canvas>
  )
}

export default Scene;
