import { useRef, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import sewerBackground from '../../assets/sewer.jpeg';
import drippingAudio from '../../assets/177958__sclolex__water-dripping-in-cave.mp3';

const Page404 = (props) => {

  const { windowWidth, windowHeight } = props;
  const [buttonPosition, setButtonPosition] = useState({
    left: (Math.random() * windowWidth * 0.75),
    top: Math.max((Math.random() * windowHeight - 180), 120)
  });
  const torch = useRef();
  const dripping = useRef();

  useEffect(() => {
    const handleMouseMove = (e) => {
      let x, y;
      if (e.touches && e.touches[0]) {
        x = e.touches[0].clientX;
        y = e.touches[0].clientY;
      } else {
        x = e.clientX;
        y = e.clientY;
      }
      if (torch.current) {
        torch.current.style.transform = `translate(${x - 200}px, ${y - 200}px)`;
      }
    }

    const handleClick = () => {
      if (dripping.current) {
        dripping.current.play()
          .then(() => window.removeEventListener('click', handleClick, false))
          .catch((e) => { console.error(e); });
      }
    }

    window.addEventListener('click', handleClick, false);
    window.addEventListener('mousemove', handleMouseMove, false);
    window.addEventListener('touchmove', handleMouseMove, false);

    setButtonPosition({
      left: (Math.random() * windowWidth * 0.75),
      top: Math.max(Math.random() * windowHeight - 240, 120)
    });

    return () => {
      window.removeEventListener('click', handleClick, false);
      window.removeEventListener('mousemove', handleMouseMove, false);
      window.removeEventListener('touchmove', handleMouseMove, false);
    }
  }, [windowWidth, windowHeight]);

  return (
    <div
      className="page--404"
      style={{
        backgroundImage: `url(${sewerBackground})`,
        height: windowHeight + 'px'
      }}
    >
      <div className="page--404__torch__wrapper">
        <div className="page--404__torch" ref={torch} />
      </div>
      <Link
        to="/"
        className="page--404__button button b04"
        style={{
          left: buttonPosition.left + 'px',
          top: buttonPosition.top + 'px'
        }}
      >Turn back, weary traveller—you are lost!</Link>
      <audio
        ref={dripping}
        src={drippingAudio}
        controls={false}
        playsInline={true}
        autoPlay={true}
        loop={true}
      />
    </div>
  )
}

export default Page404;