import { useEffect, useState } from "react";

const PostcodeFinder = (props) => {

  const { currentItem, setCurrentItem, postcodeInputValue, setPostcodeInputValue } = props;

	const [ postcodeError, setPostcodeError ] = useState(false);
	const [ postcodeSuccess, setPostcodeSuccess ] = useState(false);

  const handleSubmitPostcode = (e) => {
    e.preventDefault();
    fetch(`https://api.postcodes.io/postcodes/${postcodeInputValue.replace(' ', '')}`, {
      method: 'get',
      headers: { 'Content-Type': 'application/json' },
    })
    .then(response => response.json())
    .then(response => {
      if (response?.result?.latitude && response?.result?.longitude) {
        setCurrentItem({
          ...currentItem,
          location: {
            lat: response.result.latitude,
            lng: response.result.longitude
          }
        });
				setPostcodeSuccess(true);
      } else {
				setPostcodeError(true);
			}
    })
		.catch(error => {
			setPostcodeError(true);
		});
  }

	useEffect(() => {
		setPostcodeError(false);
		setPostcodeSuccess(false);
	}, [ postcodeInputValue ]);

  return (
    <div
      className="postcode__wrapper"
    >
      <label
        htmlFor="postcode"
      ><strong>If you know the postcode of your place oddity’s location, pop it into the box below and click Confirm. You’ll see the border of the box turn from pink to green.</strong></label>
      <input
        type="text"
        name="postcode"
        placeholder="Enter postcode..."
        className="editor__content__item__input--text"
        value={postcodeInputValue}
        onChange={(e) => {
          setPostcodeInputValue(e.target.value);
        }}
				style={ {
					borderColor: postcodeError === true ? 'red' : postcodeSuccess ? 'limegreen' : undefined,
					backgroundColor: postcodeError === true ? 'rgba(255, 220, 220, 1)' : postcodeSuccess ? 'rgba(220, 255, 220, 1)' : undefined
				} }
      />
      <button
        className="button b04"
        onClick={handleSubmitPostcode}
      >Confirm</button>
			{
				postcodeError === true &&
				<p className="postcode__error">Sorry, we couldn’t find that postcode. Please try again.</p>
			}
      <p>If you don’t know the exact postcode, click on the map to place the marker, then drag the marker on the map to reposition it. You can also zoom and pan the map to get a closer look.</p>
    </div>
  );
}

export default PostcodeFinder;