// import parse from 'html-react-parser';
import LazyloadImage from '../../LazyloadImage';
// import VideoPlayer from '../../VideoPlayer';
// import AudioPlayer from '../../AudioPlayer';
import { useEffect } from 'react';

// const FragmentContentText = (props) => {

//   const { item } = props;
//   const { value } = item;

//   if (value) {
//     return (
//       <div className="archive__fragment__content__item__content archive__fragment__content__item__text">
//         {parse(value)}
//       </div>
//     )
//   } else {
//     return null;
//   }
// }

const FragmentContentImage = (props) => {
  const { item, gridView } = props;

  return (
    <div className="archive__fragment__content__item__content">
      <LazyloadImage
        image={item.value}
        w={gridView === true ? 2048 : null}
        h={gridView === true ? 2048 : null}
        desaturate={true}
      />
    </div>
  )
}

// const FragmentContentVideo = (props) => {
//   const { item } = props;

//   return (
//     <div className="archive__fragment__content__item__content">
//       <VideoPlayer url={item.value} />
//     </div>
//   )
// }

// const FragmentContentVideoUrl = (props) => {

//   const { item } = props;
//   const { value } = item;

//   return (
//     <div className="archive__fragment__content__item__content">
//       <VideoPlayer url={value} />
//     </div>
//   )
// }

// const FragmentContentFile = (props) => {
//   const { item } = props;

//   return (
//     <div className="archive__fragment__content__item__content">
//       <p>{item.value}</p>
//     </div>
//   )
// }

// const FragmentContentAudio = (props) => {
//   const { item } = props;

//   return (
//     <div className="archive__fragment__content__item__content">
//       <div className="archive__fragment__content__item__content__audio__wrapper">
//         <p>Play audio</p>
//         <AudioPlayer url={item.value} />
//       </div>
//     </div>
//   )
// }

// const FragmentContentAudioUrl = (props) => {
//   const { item } = props;
//   const { value } = item;

//   return (
//     <div className="archive__fragment__content__item__content">
//       <AudioPlayer url={value} />
//     </div>
//   )
// }

const ContentItemContent = (props) => {

  const { item } = props;

	const text = 'The title is faded. Click to look a little closer.';

  if (item.field) {
    if (item.field.name === 'text') {
      return (
        <p className='archive__fragment__content__item--instruction'>{ text }</p>
      );
    } else if (item.field.name === 'image') {
      return <FragmentContentImage {...props} contentItem={item} />;
    } else if (item.field.name === 'video') {
      return (
        <p className='archive__fragment__content__item--instruction'>{ text }</p>
      );
    } else if (item.field.name === 'video_url') {
      return (
        <p className='archive__fragment__content__item--instruction'>{ text }</p>
      );
    } else if (item.field.name === 'file') {
      return (
        <p className='archive__fragment__content__item--instruction'>{ text }</p>
      );
    } else if (item.field.name === 'audio') {
      return (
        <p className='archive__fragment__content__item--instruction'>{ text }</p>
      );
    } else if (item.field.name === 'audio_url') {
      return (
        <p className='archive__fragment__content__item--instruction'>{ text }</p>
      );
    } else {
      return null;
    }
  } else {
    return null;
  }
}

const FragmentContent = (props) => {

  const { item, gridView } = props;
  const { content } = item;


  useEffect(() => {

  }, []);

  return (
    <div className="archive__fragment__content__item">
      <ContentItemContent item={content} gridView={gridView} />
    </div>
  )
}

export default FragmentContent;