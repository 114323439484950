import { useEffect, useMemo, useState } from "react";
import { Html } from "@react-three/drei";
import { getImage } from "../../utils/cockpit";

const svgWidth = 637.55;
const svgHeight = 947.55;

const MapItem = (props) => {
  const { item, setCurrentItem, activeToolTip, setActiveToolTip, currentItem, selectedLayer, selectedLocation, index } = props;
  const [texturePath, setTexturePath] = useState('');
  const [itemIsVisible, setItemIsVisible] = useState(true);

	const location = useMemo(() => {
		if (item?.locationAdjusted?.lat && item?.locationAdjusted?.lng && item?.adjustLocation === true) {
			return item.locationAdjusted;
		} else {
			return item.location;
		}
	}, [ item?.location, item?.locationAdjusted, item?.adjustLocation, ]);

  useEffect(() => {
    if (item.icon?.path) {
      getImage(item.icon.path, 32, 32, 100, setTexturePath);
    }
  }, [item.icon]);

  useEffect(() => {
    if (!selectedLayer._id && !selectedLocation._id) {
      setItemIsVisible(true);
    } else {
      let isVisible = true;
      if (selectedLayer._id && item.layers) {
        if (!item.layers.some(layer => layer._id === selectedLayer._id)) {
          isVisible = false;
        }
      } else if (selectedLayer._id && !item.layers) {
        isVisible = false;
      }
      if (selectedLocation._id && item.locations) {
        if (!item.locations.some(location => location._id === selectedLocation._id)) {
          isVisible = false;
        }
      } else if (selectedLocation._id && !item.locations) {
        isVisible = false;
      }
      setItemIsVisible(isVisible);
    }
  }, [item.layers, item.locations, selectedLayer, selectedLocation]);

  if (itemIsVisible === true) {
    return (
      <group
        position={[
          ((location.lng + 10.5) / (10.5 + 1.87)) * (svgWidth * 1.31),
          (((-location.lat + 49.95) / (49.95 - 60.8)) * (svgHeight * 1.2)) - (svgHeight * 1.29),
          2 + index * 0.025
        ]}
      >
        <Html>
          <button
            className={`map__item button--plain${item._id === currentItem._id ? ' active' : ''}`}
            onClick={() => {
              setCurrentItem({
                ...item
              });
              setActiveToolTip('');
              if (item.title_slug && item.title_slug !== '') {
                props.navigate(`/place-oddity/${item.title_slug}-${item._created}`);
              }
            }}
            onMouseMove={() => {
              if (activeToolTip !== item.title) {
                setActiveToolTip(item.title);
              }
            }}
            onMouseLeave={() => {
              setActiveToolTip('');
            }}
          >
            {
              texturePath !== '' ?
                <img
                  src={texturePath}
                  alt={item.icon.title}
                  className="map__item__icon"
                />
                :
                <div className="map__item__icon map__item__icon--no-image" />
            }
          </button>
        </Html>
      </group>
    );
  } else {
    return null;
  }
}

const MapItems = (props) => {

  const { camera, items, currentItem, setCurrentItem } = props;

  return (
    <group rotation={[-Math.PI, 0, 0]}>
      {
        items.map(
          (item, i) => (
            <MapItem
              key={i}
              item={ item }
              index={i}
              setCurrentItem={setCurrentItem}
              currentItem={currentItem}
              camera={camera}
              {...props}
            />
          )
        )
      }
    </group>
  )
}

export default MapItems;