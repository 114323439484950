import { useState, useEffect } from 'react';
import { fetchCockpitData } from '../../utils/cockpit';
import ItemContentEditorReorder from './ItemContentEditorReorder';
import ContentItemContent from './ItemContent/ContentItemContent';

const ContentItem = (props) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const { item, currentItem, setCurrentItem, index } = props;

  return (
    <li className="editor__content__item">
      <div className="editor__item__content__heading">
        <h4 className="editor__item__content__heading">{item.field.label} {item.display}</h4>
        <div className="editor__item__content__heading__buttons">
          <button
            className="button b04 auto-width editor__content__item__delete"
            onClick={(e) => {
              e.preventDefault();
              const newContentArray = [...currentItem.content];
              newContentArray.splice(index, 1);
              setCurrentItem({
                ...currentItem,
                content: [...newContentArray]
              });
            }}
          >Delete</button>
          <button
            className="button b04 auto-width editor__content__item__collapse"
            onClick={(e) => {
              e.preventDefault();
              setIsCollapsed(!isCollapsed);
            }}
          >{isCollapsed === true ? 'Show' : 'Hide'}</button>
        </div>
      </div>
      {
        isCollapsed === false &&
        <ContentItemContent {...props} />
      }
    </li>
  )
}

const ItemContentEditorAddRowMenu = (props) => {

  const { currentItem, setCurrentItem, setAddItemMenuIsActive } = props;

  const [rowTypes] = useState([
    { name: "text", type: "wysiwyg", label: "Text" },
    { name: "image", type: "image", label: "Image" },
    { name: "video_url", type: "text", label: "Video URL", options: { type: "url" } },
    { label: "Audio URL", name: "audio_url", options: { type: "url" }, type: "text" }
  ]);

  const handleAddRow = (i) => {
    const newContent = [...currentItem.content];
    newContent.push({
      field: { ...rowTypes[i] }
    });
    setCurrentItem({
      ...currentItem,
      content: [...newContent]
    });
  }

  return (
    <nav className="editor__content__menu--add-row">
      <ul className="editor__content__menu--add-row__list">
        {
          rowTypes.map((item, i) => (
            <li className="editor__content__menu--add-row__list__item" key={i}>
              <button className="button b04 auto-width editor__content__menu--add-row__list__item__button"
                onClick={(e) => {
                  e.preventDefault();
                  handleAddRow(i);
                }}
              >
                {item.label}
              </button>
            </li>
          ))
        }
        <li className="editor__content__menu--add-row__list__item">
          <button className="button b04 auto-width editor__content__menu--add-row__list__item__button editor__content__menu--add-row__list__item--cancel"
            onClick={(e) => {
              e.preventDefault();
              setAddItemMenuIsActive(false);
            }}
          >Cancel</button>
        </li>
      </ul>
    </nav>
  )
}

const ItemContentEditor = (props) => {

  useEffect(() => {
    const handleFetchCockpitData = (response) => {
    }
    fetchCockpitData('fragments', handleFetchCockpitData, true, { published: true });
  }, []);

  const { currentItem } = props;
  const { content } = currentItem;
  const [addItemMenuIsActive, setAddItemMenuIsActive] = useState(false);
  const [reorderIsActive, setReorderIsActive] = useState(false);

  return (
    <div className="col-xxs-12">
      <div className="editor__content no-margins">
        <p className="editor__item__content__heading">Add some content to your place oddity! This can be text, images, videos, or audio</p>
        {
          reorderIsActive === false &&
          <ul className="editor__content__rows">
            {
              content &&
              content[0] &&
              content.map((item, i) => (
                <ContentItem {...props} key={i} index={i} item={item} />
              ))
            }
          </ul>
        }
        <div className="editor__content__section__content">
          {
            addItemMenuIsActive === false &&
              <button
                className="button b04 auto-width editor__content__button--add-row"
                onClick={(e) => {
                  e.preventDefault();
                  setAddItemMenuIsActive(true);
                }}
              >Add content</button>
					}
					{
						addItemMenuIsActive === true &&
						<ItemContentEditorAddRowMenu {...props} setAddItemMenuIsActive={ setAddItemMenuIsActive } />
					}
          {
            content && content[1] && reorderIsActive === false &&
            <button
              className="button b04 auto-width editor__content__button--reorder"
              onClick={(e) => {
                e.preventDefault();
                setReorderIsActive(true);
              }}
            >Reorder content</button>
          }
          {
            content && content[1] && reorderIsActive === true &&
            <ItemContentEditorReorder {...props} setReorderIsActive={ setReorderIsActive } />
          }
        </div>
      </div>
    </div>
  )
}

export default ItemContentEditor;