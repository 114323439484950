import { useRef, useEffect, useState, useMemo } from 'react';
import { Icon, Point } from 'leaflet';
import { Marker } from 'react-leaflet';
import { cockpitUrl } from "../../utils/cockpit";
import placeholderDot from '../../../assets/placeholder-dot.png';

const FragmentMarker = (props) => {
  const { item } = props;
  const markerRef = useRef(null);
  const [itemIcon, setItemIcon] = useState({});

  useEffect(() => {
    setItemIcon(new Icon({
      iconUrl: item.icon?.path && item.icon?.path !== '' ? cockpitUrl + item.icon.path : placeholderDot,
      iconRetinaUrl: item.icon?.path && item.icon?.path !== '' ? cockpitUrl + item.icon.path : placeholderDot,
      iconAnchor: null,
      popupAnchor: null,
      shadowUrl: null,
      shadowSize: null,
      shadowAnchor: null,
      iconSize: new Point(32, 32),
      className: 'leaflet-div-icon'
    }));
  }, [item.icon, item.location]);

	const location = useMemo(() => {
		if (item?.locationAdjusted?.lat && item?.locationAdjusted?.lng && item?.adjustLocation === true) {
			return item.locationAdjusted;
		} else {
			return item.location;
		}
	}, [ item?.location, item?.locationAdjusted, item?.adjustLocation, ]);

  if (location?.lat) {
    return (
      <Marker
        draggable={true}
        position={
          location?.lat && location?.lng ? [location.lat, location.lng] : [53.126766, -1.422081]
        }
        ref={markerRef}
        icon={itemIcon}
      >
      </Marker>
    );
  } else {
    return null;
  }
}

export default FragmentMarker;