import { useState } from 'react';

const ItemContentEditorText = (props) => {

  const { item, index, currentItem, setCurrentItem } = props;
  const { value } = item;
  const [initialValue] = useState(value);

  const handleEditorChange = (value) => {
    const newCurrentItem = { ...currentItem };
		let formattedValue = value.replace(/\n/g, '<br />');
		formattedValue = `<p>${formattedValue}</p>`;
		formattedValue = formattedValue.replace(/<br \/>/g, '</p><p>');

    newCurrentItem.content[index].value = formattedValue;
    setCurrentItem(newCurrentItem);
  }

  return (
    <div className="editor__content__item__content editor__content__item__text">
			<textarea
				rows={10}
				style={ {
					width: '100%',
					height: '100%',
					border: 'none',
					padding: '0.5em',
					margin: '0',
					outline: 'none',
					resize: 'none',
					fontFamily: 'inherit',
					fontSize: 'inherit',
					lineHeight: 'inherit',
					backgroundColor: 'transparent',
					color: 'inherit'
				} }
				value={initialValue} onChange={(e) => handleEditorChange(e.target.value)} />
    </div>
  )
};

export default ItemContentEditorText;