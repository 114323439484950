import { List, arrayMove } from 'react-movable';

const ItemContentEditorReorder = (props) => {

  const { currentItem, setCurrentItem, setReorderIsActive } = props;
  const { content } = currentItem;

  return (
    <div className="editor__item__content__reorder">
      <List
        values={content}
        onChange={({ oldIndex, newIndex }) =>
          setCurrentItem({
            ...currentItem,
            content: arrayMove(content, oldIndex, newIndex)})
        }
        renderList={({ children, props }) => <ul className='editor__item__content__reorder__list' {...props}>{children}</ul>}
        renderItem={({ value, props }) => <li className='editor__item__content__reorder__list__item' {...props}>{value.field.label}</li>}
      />
      <button
				className="button b04 auto-width"
				style={ {
					width: 'auto',
				} }
        onClick={ (e) => {
          e.preventDefault();
          setReorderIsActive(false);
        } }
      >
				<span className="button__inner">
					Finished Reordering
				</span>	
			</button>
    </div>
  )
}

export default ItemContentEditorReorder;