import { cockpitUrl } from '../../utils/cockpit';

const Font = (props) => {

  const { item, className } = props;

  if (item.font?.path) {

    return (
      <style>
        {`
          @font-face {
            font-family: "${item._id}";
            src: url(${cockpitUrl + '/storage/uploads' + item.font.path}) format('woff');
            font-weight: 300;
            font-style: normal;
            font-display: swap;
          }
          .${className} {
            font-family: "${item._id}";
          }
          .${className} *:not(.button) {
            font-family: "${item._id}";
          }
        `}
      </style>
    );
  } else if (item.fontStyle) {
    return (
      <style>
        {`
          .${className} {
            font-family: ${item.fontStyle};
          }
          .${className} *:not(.button) {
            font-family: ${item.fontStyle};
          }
        `}
      </style>
    );
  } else {
    return null;
  }
};

export default Font;