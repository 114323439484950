import { motion } from 'framer-motion';
import Fragment from '../../views/Fragment';

const MicroficheActiveFragment = (props) => {

  const { activeFragment, setActiveFragment } = props;

  return (
    <motion.div className="microfiche--responsive__active-fragment">
      <div className="microfiche--responsive__active-fragment__inner">
        <Fragment { ...props } returnPath={ '/archive' } item={ activeFragment } setCurrentItem={ setActiveFragment } />
      </div>
    </motion.div>
  );
};

export default MicroficheActiveFragment;