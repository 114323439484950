import { useRef, useState, useEffect } from 'react';
import Header from './Header';
import Grid from './Grid';

const MicroficheScreen = (props) => {

  const { siteData, sortBy, selectedLayer, selectedLocation, screenContents, zoomLevel, setZoomLevel } = props;
  const { fragments } = siteData;
  const [items, setItems] = useState([]);
  const viewArchiveInner = useRef();
  const draggableElement = useRef();
  const [ zoomAmount, setZoomAmount ] = useState((zoomLevel + 1) ** 2);
  const startPoint = useRef({});
  const position = useRef({ x: 0, y: 0 });

	const mouseIsDownRef = useRef();

  useEffect(() => {
    let timeout;

    const zoomAmountNumber = (zoomLevel + 1) ** 2;
    setZoomAmount(zoomAmountNumber);

    if (draggableElement.current) {
      draggableElement.current.style.transition = 'transform 0.5s ease';
      timeout = setTimeout(() => {
        if (draggableElement.current) {
          draggableElement.current.style.transition = undefined;
        }
      }, 500);
			
      draggableElement.current.style.transform = `scale(${ zoomAmountNumber }) translate(${ zoomLevel === 0 ? 0 : (position.current.x) / zoomAmountNumber }px, ${ zoomLevel === 0 ? 0 : (position.current.y) / zoomAmountNumber }px)`;
    }

    return () => {
      clearTimeout(timeout);
    }
  }, [ zoomLevel ]);

  const handleMouseDown = (event) => {
    const clientX = event.touches ? event.touches[0].clientX : event.clientX;
    const clientY = event.touches ? event.touches[0].clientY : event.clientY;
    if (draggableElement.current) {
      startPoint.current = {
        x: position.current.x,
        y: position.current.y,
        clientX: clientX,
        clientY: clientY
      };
			mouseIsDownRef.current = true;
    }
  }

  const handleMouseMove = (event) => {
    if (typeof startPoint.current.x === 'number' && draggableElement.current && mouseIsDownRef.current === true) {
      const clientX = event.touches ? event.touches[0]?.clientX : event.clientX;
      const clientY = event.touches ? event.touches[0]?.clientY : event.clientY;

      const differenceX = clientX - startPoint.current.clientX;
      const differenceY = clientY - startPoint.current.clientY;

      let x = Math.max(viewArchiveInner.current.offsetWidth / 2 - draggableElement.current.getBoundingClientRect().width / 2, (Math.min(0, startPoint.current.x + differenceX)));
      let y = Math.max(
        viewArchiveInner.current.offsetHeight - draggableElement.current.getBoundingClientRect().height,
        (Math.min(0, startPoint.current.y + differenceY))
      );

      position.current.x = x;
      position.current.y = y;
      
      draggableElement.current.style.transform = `scale(${zoomAmount}) translate(${position.current.x / zoomAmount}px, ${position.current.y / zoomAmount}px)`;
    }
  }

  const handleMouseUp = () => {
    startPoint.current = {};
		mouseIsDownRef.current = false;
  }

  const handleWheel = (e) => {
    const deltaX = e.deltaX;
    const deltaY = e.deltaY;

    const differenceX = -deltaX;
    const differenceY = -deltaY;

    startPoint.current = {
      x: position.current.x,
      y: position.current.y
    };

    let x = Math.max(viewArchiveInner.current.offsetWidth - draggableElement.current.getBoundingClientRect().width, (Math.min(0, startPoint.current.x + differenceX)));
    let y = Math.max(viewArchiveInner.current.offsetHeight - draggableElement.current.getBoundingClientRect().height, (Math.min(0, startPoint.current.y + differenceY)));

    position.current.x = x;
    position.current.y = y;
      
    draggableElement.current.style.transform = `scale(${zoomAmount}) translate(${position.current.x / zoomAmount}px, ${position.current.y / zoomAmount}px)`;
  }

  useEffect(() => {
    if (fragments?.entries) {

			let fragmentsToSort = fragments.entries;
			const sortedArray = fragmentsToSort.sort((a, b) => {
				if (sortBy === 'alphabetically') {
					return a.title > b.title ? 1 : -1;
				} else if (sortBy === 'reverse alphabetically') {
					return a.title < b.title ? 1 : -1;
				} else if (sortBy === 'chronologically') {
					return a._created > b._created ? 1 : -1;
				} else {
					return a._created < b._created ? 1 : -1;
				}
			});

			const filteredArray = [];

			for (let i = 0; i < sortedArray.length; i++) {
				let isIncluded = true;
				const entry = sortedArray[i];

				if (selectedLayer?._id?.length > 0) {
					let hasLayer = false;
					if (entry.layers?.length > 0) {
						for (let layer of entry.layers) {
							if (selectedLayer._id === layer._id) {
								hasLayer = true;
							}
						}
					}
					if (!hasLayer) {
						isIncluded = false;
					}
				}
				if (selectedLocation?._id?.length > 0) {
					let hasLocation = false;
					if (entry.locations?.length > 0) {
						for (let location of entry.locations) {
							if (selectedLocation._id === location._id) {
								console.log('location._id', location._id);
								hasLocation = true;
							}
						}
					}
					if (!hasLocation) {
						isIncluded = false;
					}
				}
				if (isIncluded) {
					filteredArray.push(entry);
				}
			}

      const itemsArray = [];

      for (let entry of filteredArray) {
        if (entry.content) {
          for (let contentItem of entry.content) {
            const newContentItem = {
              layers: [...entry.layers],
              locations: [...entry.locations],
              location: { ...entry.location },
							title_slug: entry.title_slug,
              title: entry.title,
              _created: entry._created,
              _id: entry._id,
              content: { ...contentItem }
            }
            itemsArray.push(newContentItem);
          }
        }
      }
      setItems([ ...itemsArray ]);
    }
  }, [ fragments, sortBy, selectedLayer, selectedLocation, ]);
  
  const clickHandler = (event) => {
    if (event.detail === 2) {
      if (zoomLevel < 5) {
        setZoomLevel(zoomLevel + 1);
      } else {
        setZoomLevel(0);
      }
    }
  }

  return (
    <div className="microfiche-viewer--responsive">
      <div
        ref={ viewArchiveInner }
        className={ `view--archive__inner view--archive__inner--hidden` }
        onMouseDown={ handleMouseDown }
        onMouseMove={ handleMouseMove }
        onMouseUp={ handleMouseUp }
        onTouchStart={ handleMouseDown }
        onWheel={ handleWheel }
        onTouchMove={ handleMouseMove }
        onTouchEnd={ handleMouseUp }
        onBlur={ handleMouseUp }
        onMouseLeave={ handleMouseUp }
        onClick={ clickHandler }
      >
        <div
          style={ {
            transformOrigin: '0 0'
          } }
          ref={draggableElement}
          className="archive__content"
        >
          <div
            className="archive__content__inner"
            ref={screenContents}
          >
            <Header {...props} items={items} />
            <Grid
              itemsArray={ items }
							originalItems={fragments?.entries}
              draggableElement={draggableElement}
              {...props}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default MicroficheScreen;