const ToolTip = (props) => {

  const { activeToolTip, closeButton, setActiveToolTip } = props;

  if (activeToolTip !== '') {
    return (
      <nav className="nav--panel landing-page__nav--tool-tip">
        <div className="landing-page__nav--tool-tip__inner">
          {
            closeButton === true && typeof setActiveToolTip !== 'undefined' &&
            <button
              className="landing-page__nav--tool-tip__close-button button--plain"
              aria-label={`Close information dialog`}
              onClick={() => setActiveToolTip('')}
            />
          }
          {activeToolTip}
        </div>
      </nav>
    )
  } else {
    return null;
  }
}

export default ToolTip;