import { useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import { cockpitUrl } from '../utils/cockpit';
import * as _ from 'underscore';
import { durationInSecondsGetHours, durationInSecondsGetMinutes, durationInSecondsGetSeconds } from '../utils/duration.js';

const AudioPlayer = (props) => {

  const { url, isLooping } = props;
  const [isPlaying, setIsPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);

  const player = useRef();

  const getCurrentTime = (e) => {
    const timeElapsed = e.playedSeconds;
    setCurrentTime(timeElapsed);
  }

  const getCurrentTimeThrottled = _.throttle(getCurrentTime, 120);

  const getDuration = (e) => {
    const durationInSeconds = e;
    setDuration(durationInSeconds);
  }

  const handleSeek = (e) => {
    player.current.seekTo(e.target.value);
  }

  const handlePlay = () => {
    setIsPlaying(true);
  }

  const handleSeekThrottled = _.throttle(handleSeek, 120);

  if (url) {
    return (
      <div className="audio-player__wrapper">
      <div className={`audio-player${url.indexOf('storage') === 0 ? ' audio-player--audio-element' : ''}`}>
        <div className="audio__wrapper">
          <ReactPlayer
            ref={player}
            url={url.indexOf('storage') === 0 ? `${cockpitUrl}/${url}` : url.indexOf('/storage') === 0 ? `${cockpitUrl}${url}` : url}
            className="audio-player__player"
            playsinline={true}
            playing={isPlaying}
            volume={1}
            muted={false}
            height={'100%'}
            width={'100%'}
            loop={isLooping}
            onCanPlay={(e) => {
            }}
            onPlay={handlePlay}
            onPause={() => {
              setIsPlaying(false);
            }}
              onProgress={(e) => {
              getCurrentTimeThrottled(e)
            }}
            onSeek={(e) => {
              getCurrentTimeThrottled(e)
            }}
            onDuration={(e) => {
              getDuration(e)
            }}
            />
          </div>
          <div className={`audio-player__controls player__controls`}>
            <div className="audio-player__controls__inner player__controls__inner">
              <div className="audio-player__time__wrapper player__time__wrapper">
                <p className="audio-player__time player__time audio-player__time--elapsed player__time--elapsed">
                  {durationInSecondsGetHours(currentTime)}:{durationInSecondsGetMinutes(currentTime)}:{durationInSecondsGetSeconds(currentTime)}
                </p>
                <button
                  className={`audio-player__button--play-pause player__button--play-pause ${isPlaying === false ? 'play' : 'pause'}`}
                  onClick={(e) => {
                    typeof e !== 'undefined' && e.preventDefault();
                    setIsPlaying(!isPlaying);
                  }}
                />
                <div className="audio-player__input--time__wrapper player__input--time__wrapper">
                  <input
                    type="range"
                    className="audio-player__input--time player__input--time"
                    name="time"
                    max={duration}
                    value={currentTime}
                    onChange={(e) => {
                      setIsPlaying(false);
                      setCurrentTime(e.target.value);
                      handleSeekThrottled(e);
                    }}
                    onMouseDown={() => {
                      setIsPlaying(false);
                    }}
                    onMouseUp={() => {
                      setIsPlaying(true);
                    }}
                  />
                </div>
                <p className="audio-player__time player__time audio-player__time--total player__time--total">
                  {durationInSecondsGetHours(duration)}:{durationInSecondsGetMinutes(duration)}:{durationInSecondsGetSeconds(duration)}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  } else {
    return null;
  }
}

export default AudioPlayer;