import { Link } from 'react-router-dom';

const Header = (props) => {

  const { pathname, windowDimensions, menuIsActive, setMenuIsActive, setIsArchiveCloseupView } = props;
  const { windowWidth } = windowDimensions;

  return (
    <header className={`header${menuIsActive === true ? ' menu-is-active' : ''}`}>
      <h1 className="site-title">
        <Link to="/" className="site-title__link b04 button">Place Oddities</Link>
      </h1>
      {
        windowWidth < 920 &&
        <button
          className="button b04 button--menu"
          onClick={() => {
            setMenuIsActive(!menuIsActive);
          }}
        >{menuIsActive === false ? 'Menu' : 'Close'}</button>
      }
      {
        (windowWidth >= 920 || menuIsActive === true) &&
        <nav className="header__menu">
          <Link
            to="/"
            onClick={() => {
              setMenuIsActive(false);
            }}
            className={`header__item b04 button${pathname === '/' ? ' active' : ''}`}
          >
						<span className='header__item__inner'>
							Home
						</span>
          </Link>
          <Link
            to="/archive"
            onClick={() => {
              setIsArchiveCloseupView(false);
              setMenuIsActive(false);
            }}
            className={`header__item b04 button${pathname === '/archive' ? ' active' : ''}`}
					>
						<span className='header__item__inner'>
							Local History Section
						</span>
          </Link>
          <Link
            to="/information"
            onClick={() => {
              setMenuIsActive(false);
            }}
            className={`header__item b04 button${pathname === '/information' ? ' active' : ''}`}
          >
						<span className='header__item__inner'>
							(Mudlarking) About
						</span>
          </Link>
          <Link
            to="/surprise"
            onClick={() => {
              setMenuIsActive(false);
            }}
            className={`header__item b04 button${pathname === '/surprise' ? ' active' : ''}`}
          >
						<span className='header__item__inner'>
							Sacred Well
						</span>
          </Link>
          <Link
            to="/editor"
            onClick={() => {
              setMenuIsActive(false);
            }}
            className={`header__item b04 button${pathname === '/editor' ? ' active' : ''}`}
          >
						<span className='header__item__inner'>
							Add Your Own
						</span>
          </Link>
        </nav>
      }
    </header>
  )
}

export default Header;