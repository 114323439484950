import { Fragment, useState } from 'react';
import LandingPageLayersList from './LandingPageLayersList';
import LandingPageLocationsList from './LandingPageLocationsList';

const LandingPageNav = (props) => {
  const [layersListIsExpanded, setLayersListIsExpanded] = useState(false);
  const [locationsListIsExpanded, setLocationsListIsExpanded] = useState(false);

  return (
    <Fragment>
      <LandingPageLayersList
        {...props}
        layersListIsExpanded={layersListIsExpanded}
        setLayersListIsExpanded={setLayersListIsExpanded}
        locationsListIsExpanded={locationsListIsExpanded}
        setLocationsListIsExpanded={setLocationsListIsExpanded}
      />
      <LandingPageLocationsList
        {...props}
        layersListIsExpanded={layersListIsExpanded}
        setLayersListIsExpanded={setLayersListIsExpanded}
        locationsListIsExpanded={locationsListIsExpanded}
        setLocationsListIsExpanded={setLocationsListIsExpanded}
      />
    </Fragment>
  )
}

export default LandingPageNav;