import * as THREE from 'three';
import { useRef, useEffect } from 'react';
import { useFrame } from '@react-three/fiber';
import lavaFragmentShader from '../shaders/ThreeLavaBallFragmentShader.js';
import lavaVertexShader from '../shaders/ThreeLavaBallVertexShader.js';
import cloud from '../../../assets/hi2.png';
import lavatile from '../../../assets/water3.jpg';

const LavaBall = (props) => {

  const mesh = useRef();
  const lavaUniforms = useRef({
    uniforms: {
      "fogDensity": { value: 0.45 },
      "fogColor": { value: new THREE.Vector3(0, 0, 0) },
      "time": { value: 1.0 },
      "uvScale": { value: new THREE.Vector2(3.0, 1.0) }
    }
  });

  useEffect(() => {
    const texture1 = new THREE.TextureLoader().load(cloud);
    texture1.wrapS = texture1.wrapT = THREE.RepeatWrapping
    const texture2 = new THREE.TextureLoader().load(lavatile);
    texture2.wrapS = texture2.wrapT = THREE.RepeatWrapping;

    lavaUniforms.current.uniforms["texture1"] = { value: texture1 };
    lavaUniforms.current.uniforms["texture2"] = { value: texture2 };
  }, []);

  useFrame(() => {
    lavaUniforms.current.uniforms["time"].value += 0.015;
  });

  return (
    <mesh ref={mesh} rotation={[Math.PI * -0.1, 0, 0]} position={[0, 0, -1900]}>
      <planeBufferGeometry args={[2400, 2400, 1]} />
      <shaderMaterial attach="material" uniforms={lavaUniforms.current.uniforms} fragmentShader={lavaFragmentShader} vertexShader={lavaVertexShader} />
    </mesh>
  );
}

export default LavaBall;