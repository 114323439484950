import ItemEditor from './ItemEditor';

const EditorDialog = (props) => {

  const { windowHeight, currentItem, borderImageSource, handleSaveItem, setSavePromptIsActive,
    submissionSucceeded, setSubmissionSucceeded, submissionIsProcessing, submissionFailed, savePromptIsActive } = props;

  return (
    <div
      className={`editor__dialog${currentItem.borderStyle ? ` editor__dialog--border--${currentItem.borderStyle}` : ''}`}
      style={{
        height: windowHeight - 64 - 30 + 'px',
        maxHeight: windowHeight - 64 - 30 + 'px',
        backgroundColor: currentItem.backgroundColor ? currentItem.backgroundColor : null,
        borderColor: currentItem.borderColor ? currentItem.borderColor : null,
        borderWidth: currentItem.borderStyle !== 'icon' && currentItem.borderWidth ? currentItem.borderWidth + 'px' : '1px',
        borderStyle: currentItem.borderStyle && currentItem.borderStyle !== 'icon' ? currentItem.borderStyle : currentItem.borderStyle !== 'icon' ? 'unset' : null,
        borderImageSource: currentItem.borderStyle === 'icon' && currentItem.icon ? `url(${borderImageSource})` : null,
        fontFamily: currentItem.fontStyle
      }}
    >
      {
        submissionIsProcessing === false && submissionSucceeded === false &&
        <ItemEditor
          item={currentItem}
          {...props}
        />
      }
      {
        submissionIsProcessing === true &&
        <div className="messages col-xxs-12">
          <p>Saving...</p>
        </div>
      }
      {
        submissionSucceeded === true &&
        <div className="messages col-xxs-12">
          <p>Thank you for uploading a place oddity!</p>
            <button
              className="button b04"
              onClick={() => setSubmissionSucceeded(false)}
            >Submit another?</button>
        </div>
      }
      {
        submissionFailed === true &&
        <div className="messages col-xxs-12">
          <p>Oops, something went wrong! Please check your internet connection and try again.</p>
        </div>
      }
      {
        savePromptIsActive === true &&
        <div className="editor__save-prompt">
          <button
            className="editor__save-prompt__button editor__save-prompt__button--save"
            onClick={(e) => {
              e.preventDefault();
              handleSaveItem();
              setSavePromptIsActive(false);
            }}
          >save</button>
          <button
            className="editor__save-prompt__button editor__save-prompt__button--cancel"
            onClick={(e) => {
              e.preventDefault();
              setSavePromptIsActive(false);
            }}
          >Cancel</button>
        </div>
      }
    </div>
  );
};

export default EditorDialog;