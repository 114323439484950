import { useRef } from 'react';
import { useFrame } from '@react-three/fiber';

const Lights = (props) => {

  const pointLight1 = useRef();
  const pointLight2 = useRef();

  useFrame(({ clock }) => {
    pointLight1.current.position.x = Math.sin((clock.elapsedTime / 4000)) * 6;
    pointLight2.current.position.x = Math.sin((clock.elapsedTime / 4000)) * -6;
    pointLight1.current.position.y = Math.sin((clock.elapsedTime / 4000)) * 6;
    pointLight2.current.position.y = Math.sin((clock.elapsedTime / 4000)) * 6 + 15;
  });

  return (
    <group>
      <pointLight
        intensity={0.4}
        position={[-6, 3, 6]}
        color={0xffffff}
        ref={pointLight1}
      />
      <pointLight
        intensity={0.4}
        position={[6, 3, 6]}
        color={0xffffff}
        ref={pointLight2}
      />
    </group>
  )
}

export default Lights;