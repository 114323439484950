import VideoPlayer from '../../VideoPlayer';

const ItemContentEditorVideoUrl = (props) => {

  const { item, index, currentItem, setCurrentItem } = props;
  const { value } = item;

  const handleChange = (e) => {
    const newCurrentItem = { ...currentItem };
    newCurrentItem.content[index].value = e.target.value;
    setCurrentItem(newCurrentItem);
  }

  return (
    <div className="editor__content__item__content">
      <input
        className="editor__content__item__input--text editor__content__item__input--text--video-url"
        type="url"
        name="video_url"
        value={value}
        onChange={handleChange}
      />
      {
        value && value !== '' &&
        <VideoPlayer url={value} />
      }
    </div>
  )
};

export default ItemContentEditorVideoUrl;