import { useRef, useState, useEffect } from 'react';
import * as THREE from 'three';
import { useFrame, useThree } from '@react-three/fiber';
import { useGLTF } from '@react-three/drei';

const Coin = (props) => {

  const { cameraPos, setCameraPos, camera, coinIsTossed, coinModel, fog } = props;
  const { viewport } = useThree();
  const geomRef = useRef(null);
  const point = useRef(0);

  const modelPrimitive = useGLTF(coinModel);

  const [curve, setCurve] = useState({});

  useFrame(({mouse}) => {
    geomRef.current.rotation.x += 0.1;
    if (coinIsTossed === true && curve?.type === "CatmullRomCurve3") {
      const pos = new THREE.Vector3(0, 0, 0);
      const cameraFollowPos = new THREE.Vector3(0, 0, 0);

      if (typeof curve.getPoint === 'function') {
        curve.getPoint(point.current, pos);
        geomRef.current.position.x = pos.x;
        geomRef.current.position.y = pos.y;
        geomRef.current.position.z = pos.z;

        if (point.current > 0.2) {
          curve.getPoint(point.current - 0.2, cameraFollowPos);
          camera.current.position.x = cameraFollowPos.x;
          camera.current.position.y = cameraFollowPos.y;
          camera.current.position.z = cameraFollowPos.z;
          camera.current.lookAt(geomRef.current.position.x, geomRef.current.position.y, geomRef.current.position.z);
        if (fog.current) {
            if (fog.current.far > 8) {
              fog.current.far -= 1;
            }
          }

        }

        if (point.current <= 1) {
          point.current = Math.round((point.current + 0.01) * 100) / 100;
        } else {
          setCameraPos({
            unset: true
          });
        }
      }
    } else if (coinIsTossed === false) {
      if (geomRef.current) {
        geomRef.current.position.x = ((mouse.x) / viewport.width) * 16;
        geomRef.current.position.y = ((mouse.y) / viewport.height) * 16;
        geomRef.current.position.z = camera.current.position.z - 6;
      }
    }
  });

  useEffect(() => {
    if (coinIsTossed === true) {
      let pos = { ...cameraPos };

      if (geomRef.current?.position?.x) {
        pos = { ...geomRef.current.position };
      }

      const handlePos = [
        { ...pos },
        { x: pos.x > 0 ? 0.6 : -0.6, y: 0.05, z: pos.z > 0 ? 0.6 : -0.6 },
        { x: 0, y: 0, z: 0 },
        { x: 0, y: -2, z: 0 },
        { x: 0, y: -6, z: 0 }
      ]
      .map((hand) => new THREE.Vector3(...Object.values(hand)));
      setCurve(new THREE.CatmullRomCurve3(handlePos, false, 'centripetal'));
    }
  }, [coinIsTossed, cameraPos]);

  useEffect(() => {
    if (modelPrimitive.scene?.children[0]) {
      if (modelPrimitive.scene?.children[0].material) {
        modelPrimitive.scene.children[0].material.metalness = 0.75;
      }
    }
  }, [modelPrimitive.scene])

  return (
    <group ref={geomRef} scale={[9, 9, 9]}>
      <mesh>
        <primitive object={modelPrimitive.scene} />
      </mesh>
    </group>
  )
}

export default Coin;