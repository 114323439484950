import { useEffect } from 'react';
import { AnimatePresence, motion } from 'framer-motion';

const ArchiveLayersList = (props) => {

  const { view, setIsArchiveCloseupView, siteData, selectedLayer, setSelectedLayer, layersListIsExpanded, setLayersListIsExpanded, locationsListIsExpanded, setMenuIsActive } = props;

  useEffect(() => {
    if (locationsListIsExpanded === true) {
      setLayersListIsExpanded(false);
    }
  }, [locationsListIsExpanded, setLayersListIsExpanded]);

  return (
    <nav
      className={`archive__nav--panel nav--layers${layersListIsExpanded === false ? ' condensed' : ''}${selectedLayer._id ? ' layer-is-selected' : ''}`}
      onClick={() => setMenuIsActive(false)}
    >
      <div className="nav--archive__nav__inner">
        <button
          className={`archive__nav--panel__header button--plain ${layersListIsExpanded === true ? 'open' : 'closed'}`}
          aria-label={layersListIsExpanded === true ? `Close layers panel` : `Open layers panel`}
          onClick={() => {
            setLayersListIsExpanded(!layersListIsExpanded);
          }}
        >
          <h2 className="archive__nav--panel__heading">filter by topic</h2>
        </button>
        <ul className="archive__nav__list">
          <AnimatePresence>
            {
              siteData.layers &&
              siteData.layers.entries &&
              siteData.layers.entries.map(
                (item, i) => (
                  (layersListIsExpanded === true || selectedLayer._id === item._id) &&
                  <motion.li className={`archive__nav__list__item${selectedLayer._id === item._id ? ' selected' : ''}`} key={i}>
                    <button
                      className="archive__nav__list__item__button button--plain"
                      onClick={(e) => {
												if (view === 'microfiche') {
													setIsArchiveCloseupView(true);
												}
                        e.preventDefault();
                        if (selectedLayer._id !== item._id) {
                          setSelectedLayer(item);
                        } else {
                          setSelectedLayer({});
                        }
                      }}
                    >
                      <p className="archive__nav__list__item__title">{item.title}</p>
                    </button>
                  </motion.li>
                )
              )
            }
          </AnimatePresence>
        </ul>
      </div>
    </nav>
  )
}

export default ArchiveLayersList;