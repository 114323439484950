import parse from 'html-react-parser';
import Font from '../fragments/Font';

const InformationSection = (props) => {

  const { windowDimensions, activeItemIndex, setActiveItemIndex, information, setActiveToolTip, informationPageToolTips } = props;
  const { windowHeight } = windowDimensions;

  return (
    <div
      className="information__section"
      style={{
        maxHeight: windowHeight - 120 + 'px',
        backgroundColor: information[activeItemIndex].backgroundColor
      }}
    >
      <Font item={information[activeItemIndex]} className={`information__section__inner--${information[activeItemIndex]._id}`} />
      <div className={`information__section__inner information__section__inner--${information[activeItemIndex]._id}`}>
        <header
          className="information__section__header"
          style={{
            backgroundColor: information[activeItemIndex].backgroundColor
          }}
        >
          <h2 className="information__section__heading">{information[activeItemIndex].title}</h2>
          <button
            className="information__section__button--close button b04 auto-width"
            onClick={() => {
              setActiveItemIndex(-1);
              let tooltipText = '';
              if (informationPageToolTips?.tooltips) {
                if (informationPageToolTips.tooltips[0]) {
                  tooltipText = informationPageToolTips.tooltips[Math.floor(Math.random() * informationPageToolTips.tooltips.length)].value;
                }
              }
              setActiveToolTip(tooltipText);
            }}
          >close</button>
        </header>
        <div className="information__section__content">
          {information[activeItemIndex].text ? parse(information[activeItemIndex].text) : ''}
        </div>
      </div>
    </div>
  )
}

export default InformationSection;