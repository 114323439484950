import { useRef, useState, useEffect } from "react";
import { PlaneBufferGeometry } from 'three';

import { noise } from "./perlin";

const BeachGeometry = (props) => {
  const geometry = useRef();
  const [newGeometry, setNewGeometry] = useState(new PlaneBufferGeometry(120, 120, 24, 24));

  useEffect(() => {
    noise.seed(Math.random());
    const newNewGeometry = new PlaneBufferGeometry(120, 120, 24, 24);
    let pos = newNewGeometry.getAttribute("position");
    let pa = pos.array;
    const hVerts = newNewGeometry.parameters.heightSegments + 1;
    const wVerts = newNewGeometry.parameters.widthSegments + 1;
    for (let j = 0; j < hVerts; j++) {
      for (let i = 0; i < wVerts; i++) {
        const ex = 1.6;
        pa[3 * (j * wVerts + i) + 2] =
          (noise.simplex2(i / 100, j / 100) +
            noise.simplex2((i + 200) / 50, j / 50) * Math.pow(ex, 1) +
            noise.simplex2((i + 400) / 25, j / 25) * Math.pow(ex, 2) +
            noise.simplex2((i + 600) / 12.5, j / 12.5) * Math.pow(ex, 3) +
            +(noise.simplex2((i + 800) / 6.25, j / 6.25) * Math.pow(ex, 4))) /
          2;
      }
    }
    setNewGeometry(newNewGeometry);
  }, []);

  return (
    <primitive attach="geometry" object={newGeometry} ref={geometry} />
  )
}

export default BeachGeometry;
