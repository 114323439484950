import { useRef, useState, } from "react";
import { useFrame } from "@react-three/fiber";
import { useTexture } from '@react-three/drei';
import { RepeatWrapping, DoubleSide, Vector2 } from 'three';
import BeachGeometry from './BeachGeometry';
import pebblesSeamless from '../../../assets/information/beach/Pebbles_021_4K_basecolor.jpg';
import pebblesNormalSeamless from '../../../assets/information/beach/Pebbles_021_4K_Normal.jpg';
import pebblesRoughnessSeamless from '../../../assets/information/beach/Pebbles_021_4K_Roughness.jpg';

const Beach = () => {
  const [repeat] = useState(new Vector2(12, 12));

  const pebblesTexture = useTexture(pebblesSeamless);
  const pebblesNormal = useTexture(pebblesNormalSeamless);
  const pebblesRoughness = useTexture(pebblesRoughnessSeamless);

  const mesh = useRef();
  const map = useRef();
  const normalMap = useRef();
  const roughnessMap = useRef();

  useFrame(({ mouse }) => {
    const x = mouse.x;
    const y = mouse.y;

    map.current.offset.x = x;
    map.current.offset.y = y;
    normalMap.current.offset.x = x;
    normalMap.current.offset.y = y;
    roughnessMap.current.offset.x = x;
    roughnessMap.current.offset.y = y;
  });

  return (
    <group>
      <mesh
        scale={[1, 1, 1]}
        rotation={[Math.PI / 2, 0, 0]}
        position={[0, -8, 0]}
        ref={mesh}
        onClick={(e) => {
        }}
      >
        {/* <Suspense fallback={null}> */}
          <meshPhysicalMaterial
            attach="material"
            color={"white"}
            roughness={0}
            reflectivity={0.0}
            side={DoubleSide}
            needsUpdate={true}
          >
            <primitive
              object={pebblesTexture}
              ref={map}
              attach={`map`}
              wrapS={RepeatWrapping}
              wrapT={RepeatWrapping}
              repeat={repeat}
              needsUpdate={true}
            />
            <primitive
              object={pebblesNormal}
              ref={normalMap}
              attach={`normalMap`}
              wrapS={RepeatWrapping}
              wrapT={RepeatWrapping}
              repeat={repeat}
            />
            <primitive
              object={pebblesRoughness}
              ref={roughnessMap}
              attach={`roughnessMap`}
              wrapS={RepeatWrapping}
              wrapT={RepeatWrapping}
              repeat={repeat}
            />
          </meshPhysicalMaterial>
        {/* </Suspense> */}
        <BeachGeometry />
      </mesh>
    </group>
  );
};

export default Beach;
