import { useRef, useState, useEffect } from 'react';
import Header from './Header';
import Grid from './Grid';

const MicroficheScreen = (props) => {

  const { siteData, screenContents } = props;
  const { fragments } = siteData;
  const [items, setItems] = useState([]);
  const viewArchiveInner = useRef();

  useEffect(() => {
    if (fragments?.entries) {

      const itemsArray = [];

      for (let entry of fragments.entries) {
        if (entry.content) {
          for (let contentItem of entry.content) {
            const newContentItem = {
              layers: [...entry.layers],
              locations: [...entry.locations],
              location: { ...entry.location },
              title: entry.title,
              _created: entry._created,
              _id: entry._id,
              content: { ...contentItem }
            }
            itemsArray.push(newContentItem);
          }
        }
      }
      setItems([...itemsArray]);
    }
  }, [fragments]);

  return (
    <div className="microfiche-viewer">
      <div
        ref={viewArchiveInner}
        className={`view--archive__inner view--archive__inner--hidden`}
      >
        <div
          className="archive__content"
        >
          <div
            className="archive__content__inner"
            ref={screenContents}
            style={{
              width: '100%',
              height: '846px'
            }}
          >
            <Header items={items} {...props} />
            <Grid
              itemsArray={items}
              {...props}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default MicroficheScreen;