import { useRef, useState, useEffect } from 'react';
import { Canvas, useFrame } from '@react-three/fiber';
import MicroficheModel from '../../../assets/archive/scene.glb';
import DeskModel from '../../../assets/archive/desk2.glb';
import { A11y, A11yAnnouncer } from '@react-three/a11y';
import * as THREE from 'three';
import { OrbitControls, useGLTF, useTexture, Sphere } from '@react-three/drei';
import MicroficheScreenTextured from './MicroficheScreenTextured';
import MicroficheTraySheet from './MicroficheTraySheet';
import hdri from '../../../assets/archive/hdri4.jpg';
import Camera from './Camera';
import lerp from '../../utils/lerp';

const Room = (props) => {
  const texture = useTexture(hdri);

  return (
    <Sphere
      args={[33.0, 33, 33]}
      position={[6.0, 6.0, 0]}
      rotation={[Math.PI * -0.1, Math.PI * 0.6, 0]}
    >
      <meshBasicMaterial side={THREE.BackSide}>
        <primitive
          attach="map"
          object={texture}
          anisotropy={4}
          format={THREE.RGBAFormat}
          encoding={THREE.sRGBEncoding}
          mapping={THREE.EquirectangularReflectionMapping}
          wrapS={THREE.RepeatWrapping}
          wrapT={THREE.RepeatWrapping}
          repeat={[2, 1]}
          offset={[0.6, 0]}
          offsetS={1}
        />
      </meshBasicMaterial>
    </Sphere>
  )
}

const Desk = (props) => {
  let modelPrimitive = useGLTF(DeskModel);
  const modelMesh = useRef();
  const [modelScale] = useState(4);

  return (
    <group>
      <mesh
        ref={modelMesh}
        position={[-3.4, -3.5, 0]}
        scale={[modelScale, modelScale * 1.2, modelScale]}
        onClick={(e) => {
          if (e.intersections) {
            if (e.intersections[0]) {
              // console.log(e.intersections[0].object.name);
            }
          }
        }}
      >
        <primitive object={modelPrimitive.scene} />
      </mesh>
    </group>
  )
}

const Microfiche = (props) => {
  const { screenContents, zoomIn, camera, targetCameraPosition } = props;
  let modelPrimitive = useGLTF(MicroficheModel);
  const modelMesh = useRef();
  const [modelScale] = useState(200);

  useFrame(() => {
    if (camera.current) {
      camera.current.position.x = lerp(camera.current.position.x, targetCameraPosition.current[0], 0.1);
      camera.current.position.y = lerp(camera.current.position.y, targetCameraPosition.current[1], 0.1);
      camera.current.position.z = lerp(camera.current.position.z, targetCameraPosition.current[2], 0.1);
    }
  });

  return (
    <A11y role="button">
      <group>
        <mesh
          ref={modelMesh}
          position={[0, 0, 0]}
          scale={[modelScale, modelScale, modelScale]}
          onClick={(e) => {
            if (e.intersections) {
              if (e.intersections[0]) {
                zoomIn();
              }
            }
          }}
        >
          <primitive object={modelPrimitive.scene} />
        </mesh>
        <MicroficheScreenTextured {...props} />
        <MicroficheTraySheet {...props} screenContents={screenContents} />
      </group>
    </A11y >
  )
}

const MicroficheScene = (props) => {
  const { isArchiveCloseupView, setIsArchiveCloseupView } = props;
  const camera = useRef();
  const targetCameraPosition = useRef([0, 8, 8]);

  useEffect(() => {
    targetCameraPosition.current = [0, 8, 8];
  }, [isArchiveCloseupView]);

  const zoomIn = () => {
    if (camera.current) {
      setIsArchiveCloseupView(true);
      targetCameraPosition.current = [0, 6, 2];
    }
  }

  const zoomOut = () => {
    if (camera.current) {
      setIsArchiveCloseupView(true);
      targetCameraPosition.current = [0, 8, 8];
    }
  }

  return (
    <div style={{height: '100%', width: '100%'}} className="microfiche__canvas__wrapper">
      <Canvas>
        <Camera
          isArchiveCloseupView={isArchiveCloseupView}
          camera={camera}
        />
        <directionalLight intensity={0.26} position={[0, 2, 0]} color={0xffffff} />
        <directionalLight intensity={0.26} position={[2, -2, 0]} color={0xffffff} />
        <ambientLight color={0xffffff} intensity={0.2} />
        <group>
          <Room />
          <group
            position={[0, -1.5, -4]}
            rotation={[Math.PI * 0.01, 0, 0]}
            scale={[2, 2, 2]}
          >
            <Microfiche
              {...props}
              zoomIn={zoomIn}
              zoomOut={zoomOut}
              targetCameraPosition={targetCameraPosition}
              camera={camera}
            />
            <Desk
              {...props}
              zoomIn={zoomIn}
              zoomOut={zoomOut}
            />
          </group>
        </group>
        <OrbitControls
          maxPolarAngle={Math.PI * 0.55}
          minPolarAngle={Math.PI * 0.45}
          minAzimuthAngle={Math.PI * -0.1}
          maxAzimuthAngle={Math.PI * 0.1}
          minDistance={1}
          maxDistance={10}
          enablePanning={false}
          enableZoom={isArchiveCloseupView === false}
          enableRotate={isArchiveCloseupView === false}
        />
      </Canvas>
      {
        
        <A11yAnnouncer />
      }
    </div>
  )
}

export default MicroficheScene;
