import { useState, useEffect, useRef } from 'react';
import { cockpitUrl } from '../../utils/cockpit';

const IconSelection = (props) => {

  const { handleSelectIcon, closeIconSelectionDialog, activePath } = props;
  const [iconsArray, setIconsArray] = useState([]);
  const [scrolltop, setScrolltop] = useState(0);
  const [scrollHeight, setScrollHeight] = useState(0);
  const iconsGrid = useRef();
  const [searchValue, setSearchValue] = useState('');

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
  }

  useEffect(() => {
    if (iconsArray[0]) {
      setScrollHeight(iconsGrid.current.scrollHeight - iconsGrid.current.offsetHeight);
      setScrolltop(iconsGrid.current.scrollTop);
    }
  }, [iconsArray]);

  const handleChangeScroll = (e) => {
    const scrollAmount = e.target.value;
    iconsGrid.current.scrollTop = scrollAmount;
    setScrolltop(scrollAmount);
  }

  const handleScroll = (e) => {
    const scrollAmount = e.target.scrollTop;
    setScrolltop(scrollAmount);
  }

  useEffect(() => {

    const fetchAssets = (folderId) => {
      const apiKey = process.env.REACT_APP_API_KEY;
      fetch(`${cockpitUrl}/api/cockpit/assets?token=${apiKey}`, {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          "filter": {
            "folder": folderId
          }
        })
      })
      .then(response => response.json())
        .then(response => {
          if (response.assets) {
            setIconsArray([...response.assets]);
          }
        // return response
      })
      .catch(error => {
        console.log(error);
      })
    }

    const fetchFolderId = () => {
      const apiKey = process.env.REACT_APP_API_KEY;
      fetch(`${cockpitUrl}/api/folder?token=${apiKey}&name=Icons`)
      .then(response => response.json())
        .then(response => {
          if (response._id) {
            fetchAssets(response._id);
          }
      })
      .catch(error => {
        console.log(error);
      })
    }

    fetchFolderId();

    return () => {
    };
  }, []);

  return (
    <div className={`icons__grid__wrapper${typeof closeIconSelectionDialog === 'undefined' ? ' no-close' : ''}`}>
      <div className="icons__search__wrapper">
        <label htmlFor="search">Search icons:</label>
        <input
          className="icons__grid__search"
          type="text"
          name="search"
          value={searchValue}
          onChange={handleSearch}
          placeholder="Search..."
        />
      </div>
      {
        typeof closeIconSelectionDialog !== 'undefined' &&
        <button
          className="button b04 icons__grid__button--close"
          onClick={ (e) => {
						e.preventDefault();
						closeIconSelectionDialog(e);
					} }
          aria-label="Close Icon Selection Dialog"
        >&times;</button>
      }
      {
        iconsArray[0] ?
          <div className="icons__grid__inner">
            <div
              className="icons__grid"
              ref={iconsGrid}
              onScroll={handleScroll}
            >
              {
                iconsArray.map(
                  (icon, i) => (
                    icon.mime.indexOf('image') > -1 &&
                    icon.path &&
                    (icon.path.toLowerCase().indexOf(searchValue.toLowerCase()) > -1 || searchValue === '') &&
                    <label
                      className={`editor__button--icon button--icon${activePath === '/storage/uploads' + icon.path ? ' active' : ''}`}
                      key={i}
                    >
                      <input
                        type="radio"
                        name="icon"
                        value={icon._id}
                        onClick={(e) => {
                          handleSelectIcon('/storage/uploads' + icon.path, e);
                        }}
                      />
                      <img src={cockpitUrl + '/storage/uploads' + icon.path} className="button--icon__img" alt={'hi'} />
                    </label>
                  )
                )
              }
            </div>
            <input
              type="range"
              className="icons__grid__scroll"
              max={scrollHeight}
              min={0}
              value={scrolltop}
              onChange={handleChangeScroll}
              default={0}
            ></input>
          </div>
          :
          searchValue !== '' ?
            <p>Sorry! no icons match your search</p>
            :
            ''
        }
    </div>
  )
}

export default IconSelection;