import LandingPage from '../views/LandingPage.js';
import Archive from '../views/Archive.js';
import Surprise from '../views/Surprise.js';
import { Routes, Route, Outlet, useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import Editor from '../views/Editor.js';
import ToolTip from './ToolTip';
import Information from '../views/Information.js';
import Fragment from '../views/Fragment.js';
import Preview from '../utils/Preview.js';
import Header from './Header.js';
import Page404 from '../views/Page404.js';
import { useEffect } from 'react';

const Content = (props) => {

	const location= useLocation();
  const { siteData, windowWidth, windowHeight, currentItem, activeToolTip } = props;

	useEffect(() => {
		const favicon = document.querySelector('link[rel="icon"]');
		if (location.pathname.indexOf('/place-oddity') === -1) {
			if (favicon) {
				favicon.href = '/favicon.ico';
			}
		}
	}, [ location ]);

  return (
    <div
      className="App"
      style={{
        height: windowHeight + 'px'
      }}
    >
      <div id="content">
        <AnimatePresence>
          <Routes
						key={
							props.location.pathname.indexOf('/place-oddity') === 0 || props.location.pathname.indexOf('/fragment') > -1 || props.location.pathname === '/' ? '/'
							: props.location.pathname.indexOf('/archive') > -1 ? '/archive'
							: props.location.pathname
						}
					>
            <Route
              path="/archive"
              key="Archive"
              element={
                <Archive {...props} />
              }
            >
              {
                siteData.fragments?.entries &&
                siteData.fragments.entries.map(
                  (entry, i) => (
                    <Route
                      key={ i }
                      path={`place-oddity/${entry.title_slug}-${entry._created}`}
                      element={ <div /> }
                    />
                  )
                )
              }
            </Route>
            <Route
              path="/information"
              key="Information"
              element={
                <Information {...props} />
              }
            />
            <Route
              path="/preview"
              key="Preview"
              element={<Outlet />}
            >
              <Route
                path="place-oddities"
                key="Landing Page"
                element={
                  <div>
                    <LandingPage {...props} />
                    {
                      props.previewItem._id &&
                      <Fragment {...props} fragment={props.previewItem} item={props.previewItem} />
                    }
                  </div>
                }
              />
              <Route
                path="archive"
                key="Archive"
                element={
                  <Archive {...props} />
                }
              />
              <Route
                path="information"
                key="Information"
                element={
                  <Information {...props} />
                }
              />
            </Route>
            <Route
              path="/surprise"
              key="Surprise"
              element={
                <Surprise {...props} />
              }
            />
            {
              siteData.fragments?.entries && siteData.artefacts?.entries &&
              <Route
                exact
                path="/editor"
                key="Editor"
                element={
                  <Editor {...props} />
                }
              />
            }
            <Route
              path="/"
              key={'landing page'}
              element={<LandingPage {...props} />}
            >
              <Route
                path="place-oddity"
                key="fragments"
                element={<Outlet />}
              >
                {
                  siteData.fragments?.entries &&
                  siteData.fragments.entries.map(
                    (entry, i) => (
                      <Route
                        key={i}
                        path={`${entry.title_slug}-${entry._created}`}
                        element={<Fragment {...props} fragment={entry} item={entry} />}
                      />
                    )
                  )
                }
              </Route>
              <Route
                path="*"
                key={'404'}
                element={<Page404 {...props} />}
              />
            </Route>
            <Route
              path="*"
              key={'404'}
              element={<Page404 {...props} />}
            />
          </Routes>
        </AnimatePresence>
      </div>
      {
        (windowWidth > 767 || !currentItem._id) &&
        <Header {...props} />
      }
      {
        activeToolTip !== '' &&
        <ToolTip {...props} closeButton={true} />
      }
      <Preview {...props} />
    </div>
  )
}

export default Content;