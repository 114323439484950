import { useCallback, useState } from 'react';
import ArchiveLayersList from './ArchiveLayersList';
import ArchiveLocationsList from './ArchiveLocationsList';
import { useLocation, useNavigate } from 'react-router-dom';

const ArchiveNav = (props) => {
  const { view, setView, sortBy, setSortBy, archiveNavIsOpen, setArchiveNavIsOpen, archiveExplainerIsVisible, setArchiveExplainerIsVisible, setIsArchiveCloseupView, windowWidth } = props;
  const [layersListIsExpanded, setLayersListIsExpanded] = useState(false);
  const [ locationsListIsExpanded, setLocationsListIsExpanded ] = useState(false);
  
  const toolTips = [
    'Choose ‘microfiche’ to view the archives on screen or ‘list’ for the Domesday version'
  ];

	const location = useLocation();
	const navigate = useNavigate();

  const [ toolTip, setTooltip ] = useState(toolTips[0]);

	const handleClickSortBy = useCallback((sortBy) => {
		setSortBy(sortBy);
		if (view === 'microfiche') {
			setIsArchiveCloseupView(true);
		}
	}, [ setSortBy, setIsArchiveCloseupView, view, ]);

  return (
    <div className={`archive__nav${archiveNavIsOpen === true ? ' open' : ''}`}>
      <div className="archive__nav__inner">
      <div className="archive__nav__scroll">
          <div className="archive__nav__sort"
            onMouseEnter={ () => setTooltip(toolTips[ 0 ]) }
            onMouseLeave={ () => setTooltip('') }
          >
            <h3 className="archive__nav__heading archive__nav__view__heading">View</h3>
            <button
              className={`archive__nav__button archive__nav__view__button archive__nav__button--view button--plain${view === 'list' ? ' selected' : ''}`}
              onClick={ () => {
                setView('list');
                setIsArchiveCloseupView(true);
								if (location.pathname !== '/archive') {
									navigate('/archive');
								}
              } }
            >list</button>
            <button
              className={`archive__nav__button archive__nav__view__button archive__nav__button--view button--plain${view === 'microfiche' ? ' selected' : ''}`}
              onClick={ () => {
                setView('microfiche');
                setIsArchiveCloseupView(true);
								if (location.pathname !== '/archive') {
									navigate('/archive');
								}
              } }
            >microfiche</button>
          </div>
          <div className="archive__nav__sort">
            <h3 className="archive__nav__heading archive__nav__sort__heading">Sort</h3>
            <button
              className={`archive__nav__button archive__nav__sort__button button--plain${sortBy === 'alphabetically' ? ' selected' : ''}`}
              onClick={() => handleClickSortBy('alphabetically')}
            >A-Z</button>
            <button
              className={`archive__nav__button archive__nav__sort__button button--plain${sortBy === 'reverse alphabetically' ? ' selected' : ''}`}
              onClick={() => handleClickSortBy('reverse alphabetically')}
            >Z-A</button>
            <button
              className={`archive__nav__button archive__nav__sort__button button--plain${sortBy === 'chronologically' ? ' selected' : ''}`}
              onClick={() => handleClickSortBy('chronologically')}
            >Newest first</button>
            <button
              className={`archive__nav__button archive__nav__sort__button button--plain${sortBy === 'reverse chronologically' ? ' selected' : ''}`}
              onClick={() => handleClickSortBy('reverse chronologically')}
            >Oldest First</button>
          </div>
          <ArchiveLayersList
            {...props}
            layersListIsExpanded={layersListIsExpanded}
            setLayersListIsExpanded={setLayersListIsExpanded}
            locationsListIsExpanded={locationsListIsExpanded}
            setLocationsListIsExpanded={setLocationsListIsExpanded}
          />
          <ArchiveLocationsList
            {...props}
            layersListIsExpanded={layersListIsExpanded}
            setLayersListIsExpanded={setLayersListIsExpanded}
            locationsListIsExpanded={locationsListIsExpanded}
            setLocationsListIsExpanded={setLocationsListIsExpanded}
          />
          <div className='col-xxs-12'>
            <p>{ toolTip }</p>
          </div>
        </div>
        {
          windowWidth < 768 &&
          <button
            onClick={() => setArchiveNavIsOpen(!archiveNavIsOpen)}
            className={`b04 button button--archive-nav${archiveNavIsOpen === true ? ' open' : ''}`}
            aria-label={`${archiveNavIsOpen === true ? 'close' : 'open'} navigation panel`}
          >
            <div className="button--archive-nav__inner" />
          </button> 
        }
      </div>
      <button
        className={`archive__nav__info-button button b07${archiveExplainerIsVisible === true ? ' active' : ''}`}
        onClick={ () => setArchiveExplainerIsVisible(!archiveExplainerIsVisible) }
        aria-label="open archive info"
      ><span>i</span></button>
    </div>
  )
}

export default ArchiveNav;