import { useRef, useState } from "react";
import { useFrame } from "@react-three/fiber";
import { DoubleSide, Vector2 } from 'three';
import groundSeamless from '../../../assets/information/mud/Ground_wet_003_basecolor.jpg';
import groundNormalSeamless from '../../../assets/information/mud/Ground_wet_003_normal.jpg';
import groundRoughnessSeamless from '../../../assets/information/mud/Ground_wet_003_roughness.jpg';
import groundAoSeamless from '../../../assets/information/mud/Ground_wet_003_ambientOcclusion.jpg';
import TexturePrimitive from "./TexturePrimitive";

const ModelMudMaterial = (props) => {
  const { materialRef, displacementMap } = props;
  const [repeat] = useState(displacementMap ? new Vector2(0.2, 0.2) : new Vector2(12, 12));

  const map = useRef();
  const normalMap = useRef();
  const roughnessMap = useRef();
  const aoMap = useRef();

  useFrame(({ clock }) => {

    let x = clock.elapsedTime * 0.0001;
    let y = clock.elapsedTime * 0.0001;

    if (map.current && normalMap.current && roughnessMap.current && aoMap.current) {
      map.current.offset.x = x;
      map.current.offset.y = y;
      normalMap.current.offset.x = x;
      normalMap.current.offset.y = y;
      roughnessMap.current.offset.x = x;
      roughnessMap.current.offset.y = y;
      aoMap.current.offset.x = x;
      aoMap.current.offset.y = y;
    }
  });

  return (
    <meshPhysicalMaterial
      ref={materialRef ? materialRef : null}
      attach="material"
      color={"white"}
      roughness={0.2}
      reflectivity={0.3}
      transparent={true}
      side={DoubleSide}
      needsUpdate={true}
      displacementMap={displacementMap ? displacementMap : null}
      displacementScale={displacementMap ? 0.02 : 0}
    >
      <TexturePrimitive
        textureUrl={groundSeamless}
        textureRef={map}
        attach={`map`}
        textureRepeat={repeat}
      />
      <TexturePrimitive
        textureUrl={groundNormalSeamless}
        textureRef={normalMap}
        attach={`normalMap`}
        textureRepeat={repeat}
      />
      <TexturePrimitive
        textureUrl={groundRoughnessSeamless}
        textureRef={roughnessMap}
        attach={`roughnessMap`}
        textureRepeat={repeat}
      />
      <TexturePrimitive
        textureUrl={groundAoSeamless}
        textureRef={aoMap}
        attach={`aoMap`}
        textureRepeat={repeat}
      />
    </meshPhysicalMaterial>
  );
}

export default ModelMudMaterial;