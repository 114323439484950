import { AnimatePresence } from 'framer-motion';
import { useState } from 'react';
import MicroficheActiveFragment from './MicroficheActiveFragment';
import MicroficheControls from './MicroficheControls';
import MicroficheScreen from './MicroficheResponsiveScreenContents';

const MicroficheResponsiveScreen = (props) => {

  const [ activeFragment, setActiveFragment ] = useState({});

  return (
    <div className="microfiche--responsive">
      <MicroficheScreen { ...props } {...{ activeFragment, setActiveFragment }} />
      <MicroficheControls { ...props } />
      <AnimatePresence>
      {
        activeFragment._id &&
        <MicroficheActiveFragment { ...props } {...{ activeFragment, setActiveFragment }} />
      }
      </AnimatePresence>

      <div className="microfiche--responsive__info">
        <div className="microfiche--responsive__info__inner">
          <div className='microfiche--responsive__info__header'>
            <h2 className="microfiche--responsive__info__title">Info</h2>
            <button className='microfiche--responsive__info__close' onClick={ () => setActiveFragment({}) }>close</button>
          </div>
          <p className="microfiche--responsive__info__text">Click on an item on the microfiche screen to view it.</p>
          <p className="microfiche--responsive__info__text">Use the + and - buttons next to the magnifying glass to zoom in and out.</p>
        </div>
      </div>
    </div>
  )
}

export default MicroficheResponsiveScreen;