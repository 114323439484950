import { useRef } from 'react';
import FragmentContent from './archive-fragment/FragmentContent';

const ArchiveGridItem = (props) => {

  const { item, handleActivateOriginalFragment } = props;

  const clickTimeout = useRef(null);
  const isClicked = useRef(false);
  
  if (!item) return null;

  const { title, location } = item;
  const { lat, lng } = location;

  return (
    <li className="archive__grid__item col-xxs-4 col-xs-3 col-sm-2 col-md-1">
      <div className="archive__grid__item__inner">
        <button
					className="archive__grid__item__content button--plain"
          onClick={ (event) => {
            if (isClicked.current === true) {
              clearTimeout(clickTimeout.current);
              clickTimeout.current = setTimeout(() => {
                isClicked.current = false;
              }, 200);
            } else {
              isClicked.current = true;
              clickTimeout.current = setTimeout(() => {
                handleActivateOriginalFragment(item._id);
                isClicked.current = false;
              }, 200);
            }
          } }
        >
          <h2 className="archive__grid__item__title">{title}</h2>
          <FragmentContent item={item} gridView={true} />
          <p className="archive__grid__item__location">{lat}&deg;N, {lng}&deg;E</p>
        </button>
      </div>  
    </li>
  );
}

export default ArchiveGridItem;