import { useState } from 'react';
import { fetchCockpitData, saveCollectionEntry, uploadAsset } from '../utils/cockpit';
import EditorLeaflet from '../components/editor/EditorLeaflet';
import EditorDialog from '../components/editor/EditorDialog';

const Editor = (props) => {

  const { siteData, setSiteData } = props;
  const [submissionFailed, setSubmissionFailed] = useState(false);
  const [submissionIsProcessing, setSubmissionIsProcessing] = useState(false);
  const [submissionSucceeded, setSubmissionSucceeded] = useState(false);
  const [postcodeInputValue, setPostcodeInputValue] = useState('');
  const [originalItem] = useState({
    title: '',
    title_slug: '',
    icon: {},
    layers: [],
    locations: [],
    fontStyle: 'serif',
    content: [],
    location: {
      lat: 0,
      lng: 0
    },
    published: true,
    borderStyle: 'ridge',
    borderWidth: 6
  });
  const [currentItem, setCurrentItem] = useState(originalItem);
  const [savePromptIsActive, setSavePromptIsActive] = useState({});
  const [borderImageSource, setBorderImageSource] = useState('');
  const [imagesByContentRow, setImagesByContentRow] = useState({});
  const [fontStyle, setFontStyle] = useState('');

  const updateCollectionInCockpit = (data) => {
    const handleSaveEntry = (response) => {
      setSubmissionIsProcessing(false);
      setSubmissionSucceeded(true);
      const handleFetchCockpitData = (response) => {
        setSiteData({
          ...siteData,
          fragments: response
        });
      }
      fetchCockpitData('fragments', handleFetchCockpitData, true, { published: true });
    }

    const handleSaveEntryFailed = (err) => {
      setSubmissionFailed(true);
      setSubmissionIsProcessing(false);
      console.log(err);
    }

    saveCollectionEntry('fragments', data, handleSaveEntry, handleSaveEntryFailed);
  }

  const handleSaveItem = (e) => {
    e && e.preventDefault();
    const newCurrentItem = { ...currentItem };
    let filesUploaded = 0;
    let totalImagesToUpload = 0;
    setSubmissionIsProcessing(true);

    const { content } = newCurrentItem;

    const saveFragment = () => {
      for (let i = content.length - 1; i >= 0; i--) {
        if (!content[i].value) {
          content.splice(i, 1);
        }
      }
      updateCollectionInCockpit({
      ...newCurrentItem
      }, true, 'fragments');
      setCurrentItem(originalItem);
    };

    if (content.length > 0) {
      for (let i = 0; i < content.length; i++) {
        if (content[i].field.name === 'image') {
          if (content[i].value) {
            totalImagesToUpload++;
          }
        }
      }
      
      const handleUploadSuccess = (data, i) => {
        if (data[0]) {
          content[i].value = {
            ...data[0],
            path: '/storage/uploads' + data[0].path
          };
        }
        filesUploaded++;
        if (filesUploaded >= totalImagesToUpload) {
          saveFragment(data);
        }
      }

      if (totalImagesToUpload > 0) {
        for (let i = 0; i < content.length; i++) {
          if (content[i].field.name === 'image' && content[i].value?.size) {
            uploadAsset(
              currentItem.content[i].value,
              (data) => handleUploadSuccess(data, i),
              (error) => {
                console.log(error);
                setSubmissionFailed(true);
              }
            );
          }
        }
      } else {
        saveFragment();
      }
    } else {
      saveFragment();
    }
  }

  return (
    <div className="editor__wrapper">
      <EditorLeaflet
        {...props}
        items={siteData.fragments.entries}
        setCurrentItem={setCurrentItem}
        originalItem={originalItem}
        currentItem={currentItem}
        setSavePromptIsActive={setSavePromptIsActive}
        postcodeInputValue={postcodeInputValue}
        setPostcodeInputValue={setPostcodeInputValue}
      />
      <EditorDialog
        {...props}
        currentItem={currentItem}
        setCurrentItem={setCurrentItem}
        originalItem={originalItem}
        submissionFailed={submissionFailed}
        submissionIsProcessing={submissionIsProcessing}
        submissionSucceeded={submissionSucceeded}
        setSubmissionSucceeded={setSubmissionSucceeded}
        borderImageSource={borderImageSource}
        setBorderImageSource={setBorderImageSource}
        updateCollectionInCockpit={updateCollectionInCockpit}
        setSiteData={setSiteData}
        setSubmissionIsProcessing={setSubmissionIsProcessing}
        setSubmissionFailed={setSubmissionFailed}
        savePromptIsActive={savePromptIsActive}
        setSavePromptIsActive={setSavePromptIsActive}
        postcodeInputValue={postcodeInputValue}
        setPostcodeInputValue={setPostcodeInputValue}
        handleSaveItem={handleSaveItem}
        imagesByContentRow={imagesByContentRow}
        setImagesByContentRow={setImagesByContentRow}
        fontStyle={fontStyle}
        setFontStyle={setFontStyle}
      />
    </div>
  )
}

export default Editor;