import AudioPlayer from '../../AudioPlayer';

const ItemContentEditorAudioUrl = (props) => {

  const { item, index, currentItem, setCurrentItem } = props;
  const { value } = item;

  const handleChange = (e) => {
    const newCurrentItem = { ...currentItem };
    newCurrentItem.content[index].value = e.target.value;
    setCurrentItem(newCurrentItem);
  }

  return (
    <div className="editor__content__item__content">
      <input type="url" name="audio_url" value={value} onChange={handleChange} />
      {
        value && value !== '' &&
        <AudioPlayer url={value} />
      }
    </div>
  )
};

export default ItemContentEditorAudioUrl;