import { useEffect } from 'react';
// import Header from './Header';
// import Grid from './Grid';
// import { motion } from 'framer-motion';

const MicroficheScreenTextured = (props) => {

  useEffect(() => {

  }, []);

  return (
    <mesh
      position={[0, 0.7, 0.5]}
      rotation={[Math.PI * -0.025, 0, 0]}
    >
      <meshPhongMaterial color={0x000000} />
      <planeBufferGeometry args={[2.7, 2.3, 2]} />
    </mesh>
  )
}

export default MicroficheScreenTextured;