import { useState, useEffect } from 'react';
import * as THREE from 'three';

const TexturePrimitive = (props) => {

  const { textureRef, textureUrl, textureRepeat, attach } = props;
  const [texture, setTexture] = useState({});

  useEffect(() => {
    const img = document.createElement('img');
    
    const onLoad = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      canvas.width = img.naturalWidth;
      canvas.height = img.naturalHeight;
      ctx.drawImage(img, 0, 0);
      setTexture(new THREE.TextureLoader().load(textureUrl));
    }

    img.src = textureUrl;
    img.addEventListener('load', onLoad);
  }, [textureUrl]);

  if (texture.uuid) {
    return (
      <primitive
        object={texture}
        ref={textureRef ? textureRef : null}
        attach={attach}
        wrapS={THREE.RepeatWrapping}
        wrapT={THREE.RepeatWrapping}
        repeat={textureRepeat}
        needsUpdate={true}
      />
    );
  }
};

export default TexturePrimitive;