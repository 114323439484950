import ItemContentEditorText from './ItemContentEditorText';
import ItemContentEditorImage from './ItemContentEditorImage';
import ItemContentEditorVideoUrl from './ItemContentEditorVideoUrl';
import ItemContentEditorAudioUrl from './ItemContentEditorAudioUrl';

const ContentItemContent = (props) => {

  const { item } = props;
  if (item?.field?.name === 'text') {
    return <ItemContentEditorText {...props} contentItem={item} />;
  } else if (item?.field?.name === 'image') {
    return <ItemContentEditorImage {...props} contentItem={item} />;
  // } else if (item?.field?.name === 'video') {
  //   return <ItemContentEditorVideo {...props} contentItem={item} />;
  } else if (item?.field?.name === 'video_url') {
    return <ItemContentEditorVideoUrl {...props} contentItem={item} />;
  // } else if (item?.field?.name === 'file') {
  //   return <ItemContentEditorFile {...props} contentItem={item} />;
  // } else if (item?.field?.name === 'audio') {
  //   return <ItemContentEditorAudio {...props} contentItem={item} />;
  } else if (item?.field?.name === 'audio_url') {
    return <ItemContentEditorAudioUrl {...props} contentItem={item} />;
  } else {
    return null
  }
};

export default ContentItemContent;