import { useCallback, useRef } from 'react';
import ArchiveGridItem from './ArchiveGridItem';

const Grid = (props) => {

  const { itemsArray, originalItems, windowDimensions, isMicroficheViewing, setIsMicroficheViewing, setZoomLevel, draggableElement, setActiveFragment } = props;
  const { windowHeight } = windowDimensions;
  const archiveGridWrapper = useRef();
  const archiveGridOuter = useRef();

  const handleZoomIn = (e) => {
    setIsMicroficheViewing(true);
    setZoomLevel(0);
    if (draggableElement.current) {
      // draggableElement.current.style.transform = `translate($)`
    }
  }

	const handleActivateOriginalFragment = useCallback((_id) => {
		const fragment = originalItems.find((item) => item._id === _id);
		if (!fragment) return;
		setActiveFragment({ ...fragment });
	}, [ originalItems, setActiveFragment, ]);

  return (
    <div
      className="archive__grid__wrapper container-fluid"
      ref={archiveGridWrapper}
      style={{
        minHeight: windowHeight + 'px'
      }}
    >
      <div
        className="archive__grid__outer"
        ref={archiveGridOuter}
      >
        <ul
          className={`archive__grid row${isMicroficheViewing === true ? ' microfiche' : ''}`}
        >
          {
            itemsArray[0] &&
            itemsArray.map(
              (item, i) => (
                <ArchiveGridItem key={ i } item={ item } { ...{ handleActivateOriginalFragment, } } />
              )
            )
          }
        </ul>
      </div>
      {
        isMicroficheViewing === false &&
        <button
          className="button--plain archive__grid__zoom"
          aria-label="Zoom in"
          style={{
            height: `calc(${windowHeight}px - (8rem + 40px))`
          }}
          onClick={handleZoomIn}
        ></button>
      }
    </div>
  )
}

export default Grid;