import { useEffect } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import LazyloadImage from '../LazyloadImage';

const LandingPageLocationsList = (props) => {

  const { siteData, selectedLocation, setSelectedLocation, windowDimensions, layersListIsExpanded, locationsListIsExpanded, setLocationsListIsExpanded, setMenuIsActive } = props;
  const { windowWidth, windowHeight } = windowDimensions;

  useEffect(() => {
    if (layersListIsExpanded === true) {
      setLocationsListIsExpanded(false);
    }
  }, [layersListIsExpanded, setLocationsListIsExpanded]);

  return (
    <nav
      className={`nav--panel nav--locations${locationsListIsExpanded === false ? ' condensed' : ''}${selectedLocation._id ? ' location-is-selected' : ''}`}
      onClick={() => setMenuIsActive(false)}
      style={{
        height:
          windowWidth < 1024 ?
            locationsListIsExpanded === true ? windowHeight - 96 - 60 + 'px' : null
            :
            locationsListIsExpanded === true ? windowHeight - 120 - 60 + 'px' : null,
        bottom:
          windowWidth < 1024 ?
            layersListIsExpanded === true ? windowHeight - 64 - 80 + 'px' : 64 + 'px'
            :
            layersListIsExpanded === true ? windowHeight - 90 - 80 + 'px' : 76 + 'px'
      }}
    >
      <div className="nav--landing-page__nav__inner">
        <button className="nav--panel__header button--plain"
          onClick={() => {
            setLocationsListIsExpanded(!locationsListIsExpanded);
          }}
        >
          <h2 className="nav--panel__heading">Locations</h2>
          <div className="button--nav-open-close__wrapper">
            <div
              className={`button--nav-open-close button--plain ${locationsListIsExpanded === true ? 'open' : 'closed'}`}
              aria-label={locationsListIsExpanded === true ? `Close locations panel` : `Open locations panel`}
            ></div>
          </div>
        </button>
        <AnimatePresence>
          {
            locationsListIsExpanded === true &&
            <motion.ul
              className="landing-page__nav__list"
            >
              {
                siteData.locations &&
                siteData.locations.entries &&
                siteData.locations.entries.map(
                  (item, i) => (
                    <li className={`landing-page__nav__list__item${selectedLocation._id === item._id ? ' selected' : ''}`} key={i}>
                      <button
                        className="landing-page__nav__list__item__button button--plain"
                        onClick={(e) => {
                          e.preventDefault();
                          if (selectedLocation._id !== item._id) {
                            setSelectedLocation(item);
                          } else {
                            setSelectedLocation({});
                          }
                        }}
                      >
                        <div className="landing-page__nav__list__item__icon__wrapper">
                          {
                            item.icon &&
                            item.icon.path && item.icon.path !== '' &&
                            <LazyloadImage image={item.icon} w={32} h={32} />
                          }
                        </div>
                        <p className="landing-page__nav__list__item__title">{item.title}</p>
                      </button>
                    </li>
                  )
                )
              }
            </motion.ul>
          }
        </AnimatePresence>
      </div>
    </nav>
  )
}

export default LandingPageLocationsList;