import React, { useRef, useEffect, useCallback } from "react";
import * as THREE from "three";
import { useFrame, useThree } from "@react-three/fiber";
import { useTexture } from "@react-three/drei";
import mapTexture from '../../../assets/infiniteTube/Brick_Wall_011_COLOR.jpg';
import normalTexture from '../../../assets/infiniteTube/Brick_Wall_011_NORM.jpg';
import roughTexture from '../../../assets/infiniteTube/Brick_Wall_011_ROUGH.jpg';

const InfiniteTube = (props) => {
  const { cameraPos, coinIsTossed } = props;
  const groupRef = useRef();
  const curve = useRef();
  const material = useRef();
  const { viewport } = useThree();

  const texture = useTexture(mapTexture);
  const normal = useTexture(normalTexture);
  const roughness = useTexture(roughTexture);

  const updateTube = useCallback((mouse) => {
    for (let i = groupRef.current.children.length - 1; i >= 0; i--) {
      groupRef.current.children[i].remove();
    }



    groupRef.current.children = [];
    curve.current.points[2].x = - ((mouse.x) / viewport.width);
    curve.current.points[3].x = - ((mouse.x) / viewport.width);
    curve.current.points[2].y = - ((mouse.y) / viewport.height);
    curve.current.points[3].y = - ((mouse.y) / viewport.height);
    curve.current.points[5].x = - ((mouse.x) / viewport.width);
    curve.current.points[4].x = - ((mouse.x) / viewport.width);
    curve.current.points[5].y = - ((mouse.y) / viewport.height);
    curve.current.points[4].y = - ((mouse.y) / viewport.height);

    const newGeometry = new THREE.TubeGeometry(curve.current, 20, 0.7, 50, false);
    const newTubeMesh = new THREE.Mesh(newGeometry, material.current);
    newTubeMesh.position.set(0, -0.6, 0);
    newTubeMesh.rotation.x = Math.PI / 2;
    if (groupRef.current) {
      groupRef.current.add(newTubeMesh);
    }
  }, [viewport.height, viewport.width]);

  useFrame(({mouse}) => {

    if ((curve.current?.points && groupRef.current && material.current) && coinIsTossed === true) {

      if (cameraPos.unset === true && coinIsTossed === true) {
        material.current.map.offset.x += 0.0025;
        material.current.normalMap.offset.x += 0.0025;
        material.current.roughnessMap.offset.x += 0.0025;
      }

      updateTube(mouse);
    }
  });

  useEffect(() => {

    let points = [];
    for (let i = 0; i < 9; i += 1) {
      if (i < 2) {
        points.push(new THREE.Vector3(0, 0, 1 * (i * 4)))
      } else if (i >= 8) {
        points.push(new THREE.Vector3(20, 0, 1 * (i * 4)))
      } else {
        points.push(new THREE.Vector3(1 - Math.random() * 2, 1 - Math.random() * 2, 1 * (i * 4)))
      }
    }
    curve.current = new THREE.CatmullRomCurve3(points);

    const textures = [texture, normal, roughness];

    for (let texture of textures) {
      texture.wrapS = THREE.RepeatWrapping;
      texture.wrapT = THREE.RepeatWrapping;
      texture.repeat = new THREE.Vector2(4, 1);
			texture.needsUpdate = true;
    }

    material.current = new THREE.MeshStandardMaterial({
      color: 0x99a6a0,
      metalness: 0.75,
      side: THREE.DoubleSide,
      map: texture,
      normalMap: normal,
      roughnessMap: roughness
    });

    if (coinIsTossed === false) {
      updateTube({ x: viewport.width * 0.5, y: viewport.height * 0.5 });
    }

  }, [updateTube, normal, roughness, texture, viewport.height, viewport.width, coinIsTossed]);

  return (
    <group ref={groupRef}>
    </group>
  )
}

export default InfiniteTube;