import { PerspectiveCamera } from '@react-three/drei';

const Camera = (props) => {
  const { camera } = props;

  return (
    <PerspectiveCamera
      makeDefault
      ref={camera}
      position={[0, 2, 9]}
      rotation={[Math.PI * -0.1, 0, 0]}
      near={0.1}
      far={1000000}
    >
    </PerspectiveCamera>
  )
}

export default Camera;