import { useEffect } from 'react';

const Preview = (props) => {

  const { setPreviewItem, siteData, setSiteData } = props;

  useEffect(() => {
    let prevEvent;
    const handleReceiveMessage = (event) => {
      const { data } = event;
      if (data) {
        const { collection, entry } = data;
        if (collection === 'fragments') {
          setPreviewItem({ ...entry, collection: 'fragments' });

          const updatedCollection = [...siteData.fragments.entries];

          for (let i = 0; i < updatedCollection.length; i++) {
            let item = updatedCollection[i];
            if (item._id === entry._id) {
              updatedCollection[i] = {
                ...entry,
                collection: 'fragments',
                content: {...entry.content}
              };
            }
          }

          setSiteData({
            ...siteData,
            fragments: {
              ...siteData.fragments,
              entries: [...updatedCollection]
            }
          });
        }

        if (collection === 'information') {
          setPreviewItem({
            ...entry,
            collection: 'information',
            content: {...entry.content}
          });

          if (prevEvent) {
            if (siteData?.information?.entries) {
              const updatedCollection = [...siteData.information.entries];

              for (let i = 0; i < updatedCollection.length; i++) {
                let item = updatedCollection[i];
                if (item._id === entry._id) {
                  updatedCollection[i] = {
                    ...entry,
                    collection: 'information',
                    content: {...entry.content}
                  };
                }
              }

              setSiteData({
                ...siteData,
                information: {
                  ...siteData.information,
                  entries: [...updatedCollection]
                }
              });
            }
          }
        }
      }
      prevEvent = event;
    }

    window.addEventListener('message', handleReceiveMessage, false);

    return () => {
      window.removeEventListener('message', handleReceiveMessage, false);
    }
  }, [setPreviewItem, siteData, setSiteData]);

  return null;
}

export default Preview;