import { useEffect, useState } from "react";
import { Html } from "@react-three/drei";
import { getImage } from "../../utils/cockpit";

const svgWidth = 637.55;
const svgHeight = 947.55;


const MapArtefact = (props) => {
  const { artefact } = props;
  const [texturePath, setTexturePath] = useState('');
  const { image, imageSize, link } = artefact;

  useEffect(() => {
    if (image?.path) {
      getImage(image.path, 1024, 1024, 100, setTexturePath);
    }
  }, [image]);

  return (
    <group
      position={[
        ((artefact.location.lng + 10.5) / (10.5 + 1.87)) * (svgWidth * 1.31),
        (((-artefact.location.lat + 49.95) / (49.95 - 60.8)) * (svgHeight * 1.2)) - (svgHeight * 1.29),
        2
      ]}
    >
      <Html>
        <button
          className={`map__artefact button--plain`}
          onClick={() => {
            if (link) {
              window.open(link);
            }
          }}
          style={{
            width: imageSize + 'px',
            height: imageSize + 'px'
          }}
        >
          {
            texturePath !== '' ?
              <img
                src={texturePath}
                alt={image.title}
                className="map__artefact__image"
              />
              :
              <div className="map__artefact__image map__artefact__image--no-image" />
          }
        </button>
      </Html>
    </group>

  )
}

const MapArtefacts = (props) => {

  const { camera, artefacts } = props;

  return (
    <group rotation={[-Math.PI, 0, 0]}>
      {
        artefacts.map(
          (artefact, i) => (
            <MapArtefact
              key={i}
              artefact={artefact}
              camera={camera}
              {...props}
            />
          )
        )
      }
    </group>
  )
}

export default MapArtefacts;