import { useState, useEffect } from 'react';
import TwentyP from '../../../assets/coins/twentyp.glb';
import TwoPounds from '../../../assets/coins/twopounds.glb';
import Pound from '../../../assets/coins/pound.glb';
import FiveP from '../../../assets/coins/fivep.glb';
import TenP from '../../../assets/coins/tenp.glb';
import FiftyP from '../../../assets/coins/fiftyp.glb';
import TwoP from '../../../assets/coins/twop.glb';
import OneP from '../../../assets/coins/onep.glb';

const CoinSelector = (props) => {

  const { coinModel, setCoinModel } = props;

  const [coinModels] = useState([
    TwentyP,
    TwoPounds,
    Pound,
    FiveP,
    TenP,
    FiftyP,
    TwoP,
    OneP
  ]);

  useEffect(() => {
    if (coinModel.unset === true) {
      setCoinModel(coinModels[Math.floor(Math.random() * (coinModels.length - 0.1))]);
    }
  }, [setCoinModel, coinModel, coinModels]);

  return null;
}

export default CoinSelector;