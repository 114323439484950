import { Fragment } from 'react'; 
import magnifyingGlass from '../../../assets/archive/zoom.gif';
import backArrow from '../../../assets/archive/back.png';

const MicroficheControls = (props) => {

  const { zoomLevel, setZoomLevel, isArchiveCloseupView, setIsArchiveCloseupView, selectedPageNumber, setSelectedPageNumber, pagesLength, windowWidth } = props;

  const handleClickPrevious = () => {
    if (selectedPageNumber > 0) {
      setSelectedPageNumber(selectedPageNumber - 1);
    }
  }

  const handleClickNext = () => {
    if (selectedPageNumber < pagesLength - 1) {
      setSelectedPageNumber(selectedPageNumber + 1);
    }
  }

  return (

    <header className={`archive__microfiche-reader__header${isArchiveCloseupView === true ? ' active' : ' inactive'}`}>
      {
        isArchiveCloseupView === true ?
          <div className="archive__microfiche-reader__header__item__wrapper archive__microfiche-reader__header__zoom__wrapper">
            <img className="archive__microfiche-reader__header__zoom__icon" src={magnifyingGlass} alt="magnifying glass icon" />
            <div className="archive__microfiche-reader__header__zoom__divider" />
            <div className="archive__microfiche-reader__header__zoom__buttons">
              <button
                className="archive__microfiche-reader__header__zoom__button button--plain archive__microfiche-reader__header__zoom__button--zoom-in"
                aria-label="Zoom in"
                disabled={zoomLevel >= 4 ? true : false}
                onClick={() => {
                  setZoomLevel && setZoomLevel(zoomLevel + 1);
                }}
              />
              <button
                className="archive__microfiche-reader__header__zoom__button button--plain archive__microfiche-reader__header__zoom__button--zoom-out"
                aria-label="Zoom out"
                disabled={zoomLevel <= 0 ? true : false}
                onClick={() => {
                  setZoomLevel && setZoomLevel(zoomLevel - 1);
                }}
              />
            </div>
          </div>
          :
          <div className="archive__microfiche-reader__header__item__wrapper archive__microfiche-reader__header__zoom__wrapper inactive" />
        }
      <div className="archive__microfiche-reader__header__item__wrapper archive__microfiche-reader__header__info__wrapper">
        {
          isArchiveCloseupView === true ?
          <Fragment>
            <button
              className="archive__microfiche-reader__header__button archive__microfiche-reader__header__button--previous"
              aria-label="Previous sheet"
                onClick={ () => {
                if (selectedPageNumber > 0) {
                  handleClickPrevious();
                }
              }}
              disabled={selectedPageNumber > 0 ? false : true}
              >{ windowWidth > 499 ? 'Previous' : 'Prev' }</button>
            <button
              className="archive__microfiche-reader__header__button archive__microfiche-reader__header__button--next"
              aria-label="Next sheet"
              onClick={ () => {
                if (selectedPageNumber < pagesLength - 1) {
                  handleClickNext();
                }
              }}
              disabled={selectedPageNumber < pagesLength - 1 ? false : true}
            >Next</button>
          </Fragment>
          :
          <div 
            style={{
              width: '180px'
            }}
          />
        }
      </div>

      <div className="archive__microfiche-reader__header__item__wrapper archive__microfiche-reader__header__back__wrapper">
        {
          isArchiveCloseupView === true ?
            <button
              className="archive__microfiche-reader__header__back-button button--plain"
              aria-label="Exit the microfiche reader"
              onClick={() => {
                setIsArchiveCloseupView(false);
                setZoomLevel(0);
              }}
            >
              <img
                className="archive__microfiche-reader__header__back__icon"
                style={ {
                  width: 'auto',
                  height: 'auto',
                  maxWidth: '32px',
                  maxHeight: '24px',
                  textAlign: 'center',
                }}
                src={backArrow} alt="arrow pointing forwards on a yellow background"
              />
              <span style={ {
                fontFamily: 'sans-serif',
                display: 'block',
                lineHeight: '0.6em',
                fontSize: '0.8rem',
              }}>back</span>
            </button>
            :
            <div
              className="archive__microfiche-reader__header__back-button placeholder"
            />
          }
      </div>
    </header>
  )
}

export default MicroficheControls;
