import { useState, useEffect } from 'react';
import { cockpitUrl } from './utils/cockpit.js';
import { useNavigate, useLocation } from 'react-router-dom';
import Content from './components/Content';

const App = (props) => {

  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;
  const [archiveExplainerIsVisible, setArchiveExplainerIsVisible] = useState(true);

  const [windowDimensions, setWindowDimensions] = useState({
    windowWidth: window.innerWidth,
    windowHeight: window.innerHeight
  });
  const [currentItem, setCurrentItem] = useState({});
  const [previewItem, setPreviewItem] = useState({});
  const [isArchiveCloseupView, setIsArchiveCloseupView] = useState(false);

  const [siteData, setSiteData] = useState({
    isReceived: false
  });
  const [menuIsActive, setMenuIsActive] = useState(false);

  const [activeToolTip, setActiveToolTip] = useState('');

  useEffect(() => {
    setCurrentItem({});
    setActiveToolTip('');
    setIsArchiveCloseupView(false);
  }, [pathname]);

  const handleResize = () => {
    setWindowDimensions({
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight
    });
  }

  useEffect(() => {
    if (siteData.isReceived === false) {
      const apiKey = process.env.REACT_APP_API_KEY;
      let siteDataObject = { isReceived: true };

      let dataFetched = 0;

      const collections = [
        'artefacts',
        'layers',
        'locations',
        'information'
      ];

			const filteredCollections = [
				'fragments',
			];

      const singletons = [
        'informationPageToolTips',
        'newspaperNameGenerator',
        'archive',
        'surpriseMe',
      ];

      let dataToFetch = collections.length + singletons.length + filteredCollections.length;

      const fetchDataFromCockpit = (type, name, filter) => {
        fetch(`${cockpitUrl}/api/${type}/get/${name}?token=${apiKey}`, {
					method: 'post',
					headers: { 'Content-Type': 'application/json' },
					body: JSON.stringify({
						filter: filter ? filter : null,
					})
				})
				.then(response => response.json())
				.then(response => {
					siteDataObject = {
						...siteDataObject,
						[name]: {
							...response
						}
					}
					dataFetched++;
					if (dataFetched === dataToFetch) {
						setSiteData(siteDataObject);
					}
				})
				.catch(error => {
					console.log(`${cockpitUrl}/api/${type}/get/${name}?token=${apiKey}`);
					console.log(error);
				});
      }

      for (let collection of collections) {
        fetchDataFromCockpit('collections', collection);
      }

			for (let filteredCollection of filteredCollections) {
				const filter = { published: true };
				fetchDataFromCockpit('collections', filteredCollection, filter);
			}

      for (let singleton of singletons) {
        fetchDataFromCockpit('singletons', singleton);
      }
    }
  }, [siteData.isReceived]);

  useEffect(() => {
    setWindowDimensions({
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight
    });
    let resizeTimeout;
    const setWindowResizeTimeout = () => {
      clearTimeout(resizeTimeout);
      resizeTimeout = setTimeout(
        handleResize, 200
      )
    }
    window.addEventListener('resize', setWindowResizeTimeout);

    return () => {
      clearTimeout(resizeTimeout);
      window.removeEventListener('resize', setWindowResizeTimeout);
    }
  }, []);

  if (siteData.isReceived !== false) {
    return (
      <Content
        {...props}
        currentItem={currentItem}
        activeToolTip={activeToolTip}
        setActiveToolTip={setActiveToolTip}
        siteData={siteData}
        {...siteData}
        setSiteData={setSiteData}
        windowDimensions={windowDimensions}
        {...windowDimensions}
        menuIsActive={menuIsActive}
        setMenuIsActive={setMenuIsActive}
        previewItem={previewItem}
        setPreviewItem={setPreviewItem}
        setCurrentItem={setCurrentItem}
        isArchiveCloseupView={isArchiveCloseupView}
        setIsArchiveCloseupView={setIsArchiveCloseupView}
        pathname={pathname}
        location={location}
        navigate={navigate}
        archiveExplainerIsVisible={archiveExplainerIsVisible}
        setArchiveExplainerIsVisible={setArchiveExplainerIsVisible}
      />
    );
  } else {
    return null;
  }
}

export default App;
