import { MapContainer, TileLayer, useMapEvents } from 'react-leaflet';
import FragmentMarker from './FragmentMarker';

const centre = [51.505, -0.09];

const MapEvents = (props) => {

  const { setCurrentItem, currentItem } = props;

  const mapEvents = useMapEvents({
    click(e) {
      setCurrentItem({
        ...currentItem,
        location: e.latlng
      });
    },
    locationfound(e) {
      mapEvents.flyTo(e.latlng, mapEvents.getZoom())
    },
  })

  return null;
}

const EditorLeaflet = (props) => {

  const { setCurrentItem, currentItem, windowDimensions } = props;

  return (
    <div
      className="leaflet__wrapper"
      style={{
        height: windowDimensions.windowHeight + 'px'
      }}
    >
      <MapContainer
        center={[51.505, -0.09]}
        zoom={6}
        scrollWheelZoom={true}
        zoomControl={false}
        style={{
          height: '100%'
        }}
      >
        <MapEvents setCurrentItem={setCurrentItem} currentItem={currentItem} />
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <FragmentMarker {...props} item={currentItem} />
      </MapContainer>
    </div>
  )
}

export default EditorLeaflet;
export { centre };