import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import FragmentContent from '../fragments/FragmentContent';

const ArchiveFragmentsListFragment = (props) => {

  const { item, windowWidth } = props;
  const [date, setDate] = useState('');

  useEffect(() => {
    const dateObject = new Date(parseFloat(item._created) * 1000);
    setDate(`${dateObject.getDate().toString().padStart(2, '0')}/${dateObject.getMonth().toString().padStart(2, '0')}/${dateObject.getFullYear()}`);
  }, [item._created]);

	const isOpen = useMemo(() => {
		return props.location.pathname === `/archive/place-oddity/${item.title_slug}-${item._created}`;
	}, [ item, props.location.pathname ]);

	const linkHref = useMemo(() => {
		const href = props.location.pathname === `/archive/place-oddity/${item.title_slug}-${item._created}` ? '/archive' : `/archive/place-oddity/${item.title_slug}-${item._created}`
		return href;
	}, [ item, props.location.pathname ]);

  return (
    <motion.li className={`archive__list__list__item`} id={ `${ item.title_slug }-${ item._created }` }>
      <div
        className="archive__list__list__item__button button--plain row"
      >
        <Link to={ linkHref } className="col-xxs-12 col-xs-6 col-sm-3 no-underline">
          <p className="archive__list__list__item__title">{item.title}</p>
        </Link>
        <Link to={ linkHref } className="col-xxs-12 col-xs-6 col-sm-3 no-underline">
          <p className="archive__list__list__item__title">{date}</p>
        </Link>
        {
          windowWidth >= 768 &&
          <Link to={ linkHref } className="col-sm-3 no-underline">
            <p className="archive__list__list__item__title">
							{
                item.locations &&
                item.locations.map(
                  (location, i) => (
                    <span key={i} className="archive__list__list__item__location">{location.display}{i < item.locations.length - 1 ? ', ' : ''}</span>
                  )
                )
              }
            </p>
          </Link>
        }
        {
          windowWidth >= 768 &&
          <div className="col-sm-3 archive__list__list__item__layers">
            <p className="archive__list__list__item__title">
              {
                item.layers &&
                item.layers.map(
                  (layer, i) => (
                    <span key={i} className="archive__list__list__item__layer">{layer.display}{i < item.layers.length - 1 ? ', ' : ''}</span>
                  )
                )
              }
            </p>
						{
							isOpen &&
							<Link to="/archive" className="block button b04--tiny">
								<span className="button__inner--small">close</span>
							</Link>
						}
          </div>
        }
      </div>
      <AnimatePresence>
        {
          isOpen &&
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="archive__list__list__item__content"
          >
            <FragmentContent {...props} />
          </motion.div>
        }
      </AnimatePresence>
    </motion.li>

  )
}

const ArchiveFragmentsList = (props) => {

  const { activeItem, selectedLayer, layersListIsExpanded, windowWidth, windowHeight, items, setMenuIsActive } = props;
	const scrollingElement = useRef(null);

	useEffect(() => {
		let raf = null;

		const scrollToItem = () => {
			if (scrollingElement.current) {
				const id = activeItem.title_slug + '-' + activeItem._created;
				const target = document.querySelector(`#${ id }`);
				if (target) {
					const targetTop = target.offsetTop;
					scrollingElement.current.scrollTo(0, targetTop - 120);
				}
			} else {
				raf = requestAnimationFrame(scrollToItem);
			}
		}

		if (activeItem) {
			console.log(activeItem)
			scrollToItem();
		}

		return () => {
			cancelAnimationFrame(raf);
		}
	}, [ activeItem, ]);
			
  return (
    <div
      className={`archive__list--panel list--layers${layersListIsExpanded === false ? ' condensed' : ''}${selectedLayer._id ? ' layer-is-selected' : ''}`}
      onClick={() => setMenuIsActive(false)}
      style={{ height: windowHeight + 'px' }}
    >
      <header className="list--archive__list__header container-fluid">
        <div className="row">
          <div className="col-xs-6 col-sm-3">
            <h3 className="list--archive__list__heading">title</h3>
          </div>
          <div className="col-xs-6 col-sm-3">
            <h3 className="list--archive__list__heading">date added</h3>
          </div>
          {
            windowWidth >= 768 &&
            <div className="col-sm-3">
              <h3 className="list--archive__list__heading">location</h3>
            </div>
          }
          {
            windowWidth >= 768 &&
            <div className="col-sm-3">
              <h3 className="list--archive__list__heading">topics</h3>
            </div>
          }
        </div>
      </header>
      <div className="list--archive__list__inner" ref={ scrollingElement }>
        <ul className="archive__list__list">
          {
            items &&
            items.map(
              (item, i) => (
                <ArchiveFragmentsListFragment {...props} key={i} item={item} />
              )
            )
          }
        </ul>
      </div>
    </div>
  )
}

export default ArchiveFragmentsList;