import { useRef, useState, useEffect } from 'react';
import * as THREE from 'three';
import { Canvas, useFrame, useThree } from '@react-three/fiber';
import { MapControls } from '@react-three/drei';
import LandingPageMapItems from './LandingPageMapItems';
import LandingPageMapArtefacts from './LandingPageMapArtefacts';
import mapTexturePath from '../../../assets/map.png';
import Camera from './Camera';
import lerp from '../../utils/lerp';

const SceneResizer = (props) => {
  const three = useThree();

	useFrame(({ clock }) => {
		three.setDpr(0.25);
	});

  return (null)
}

const ThreeScene = (props) => {

  const { items, artefacts, currentItem, setCurrentItem, selectedLayer, selectedLocation, siteData, setMenuIsActive } = props;
  const camera = useRef();
  const [texture, setTexture] = useState({});

  useEffect(() => {
    const textureMap = new THREE.TextureLoader().load(mapTexturePath);
    textureMap.wrapS = THREE.RepeatWrapping;
    textureMap.wrapT = THREE.RepeatWrapping;
    textureMap.minFilter = THREE.NearestFilter;
    textureMap.magFilter = THREE.NearestFilter;
    textureMap.repeat = {
      x: 1,
      y: 1
    };
    setTexture(textureMap);
  }, []);

  const clickHandler = (event) => {
    let raf = null;
    let targetZoom = camera.current.zoom;

    const animateZoom = () => {
      if (Math.abs(camera.current.zoom - targetZoom) > 0.1) {
        raf = requestAnimationFrame(animateZoom);
        camera.current.zoom = lerp(camera.current.zoom, targetZoom, 0.1);
        camera.current.updateProjectionMatrix();
      } else {
        cancelAnimationFrame(raf);
      }
    }

    if (event.detail === 2) {
      if (camera.current.zoom < 50) {
        targetZoom = camera.current.zoom * 2;
      } else {
        targetZoom = 1;
      }
      animateZoom();
    }
  }

  return (
    <div
      className="three__wrapper"
      onClick={ (event) => {
        setMenuIsActive(false);
        clickHandler(event);
      } }
    >
      <Canvas
        gl={{
          antialias: false
        }}
        onCreated={({ gl }) => {
          // gl.toneMappingExposure = 1.25;
        }}
      >
        <Camera {...props} camera={camera} />
        <SceneResizer {...props} />
        <group
          position={[
            0 - (637.55 * 0.5),
            0 + (947.75 * 0.5),
            0
          ]}
          scale={[0.7, 0.7, 0.7]}
          rotation={[Math.PI, 0, 0]}
        >
          {
            <mesh
              position={[
                0 + (637.55 * 0.65),
                0 + (947.75 * 0.67),
                0
              ]}
              scale={[0.71, 0.71, 0.71]}
              rotation={[Math.PI, 0, 0]}
            >
              <planeBufferGeometry args={[637.55 * 1.9, 947.75 * 1.9]} />
              <meshBasicMaterial color={'white'} transparent={true} map={texture ? texture : null} />
            </mesh>
          }
          {
            ((props.location.pathname === '/' || props.location.pathname.indexOf('/place-oddity') === 0)
            ||
            (props.location.pathname === '/preview/fragments' || props.location.pathname.indexOf('/preview/place-oddity') === 0)) &&
            <LandingPageMapItems
              {...props}
              camera={camera}
              siteData={siteData}
              items={items}
              currentItem={currentItem}
              setCurrentItem={setCurrentItem}
              selectedLayer={selectedLayer}
              selectedLocation={selectedLocation}
            />
          }
          {
            (props.location.pathname === '/' || props.location.pathname.indexOf('/place-oddity') === 0) &&
            <LandingPageMapArtefacts
              {...props}
              camera={camera}
              siteData={siteData}
              artefacts={artefacts}
              selectedLayer={selectedLayer}
              selectedLocation={selectedLocation}
            />
          }
        </group>
        {
          (props.location.pathname === '/' || props.location.pathname.indexOf('/place-oddity') === 0 || props.location.pathname === '/surprise') &&
          <MapControls
            enableRotate={false}
            enableZoom={true}
            enablePan={true}
            screenSpacePanning={true}
            maxZoom={100}
            minZoom={1}
            dampingFactor={0.1}
            panSpeed={1.5}
            camera={camera.current ? camera.current : null}
          />
        }
      </Canvas>
    </div>
  )
}

export default ThreeScene;