import { useState, useEffect } from 'react';
import { fetchCockpitSchema } from '../../utils/cockpit';
import { ChromePicker } from 'react-color';
import * as _ from 'underscore';

const ItemEditorColorPicker = (props) => {
  const { name, currentItem, setCurrentItem } = props;

  const handleChange = (e) => {
    setCurrentItem({
      ...currentItem,
      [name]: `rgba(${e.rgb.r}, ${e.rgb.g}, ${e.rgb.b}, ${e.rgb.a})`
    });
  }

  const handleChangeThrottled = _.throttle(handleChange, 120);

  return (
    <div className="color-picker__wrapper">
      <ChromePicker onChange={handleChangeThrottled} onChangeComplete={handleChange} color={currentItem[name]} />
    </div>
  )
}

const ItemEditorStyles = (props) => {
  const { currentItem, setCurrentItem } = props;
  const [backgroundColorIsVisible, setBackgroundColorIsVisible] = useState(false);
  const [borderColorIsVisible, setBorderColorIsVisible] = useState(false);
  const [borderStyleOptions, setBorderStyleOptions] = useState([]);
  const [retrievedBorderStyles, setRetrievedBorderStyles] = useState(false);

  useEffect(() => {
    const handleGetFragmentsSchema = (res) => {
      const fields = res.fields;
      const index = fields.findIndex(field => field.name === 'borderStyle');
      const borderStyleField = fields[index];
      const options = borderStyleField.options.options.split(', ');
      const defaultBorderStyle = borderStyleField.options.default;
      if (options) {
        setBorderStyleOptions(options);
      }
      if (defaultBorderStyle) {
        setCurrentItem({
          ...currentItem,
          borderStyle: defaultBorderStyle
        });
      }
    }
    if (retrievedBorderStyles === false) {
      fetchCockpitSchema('fragments', handleGetFragmentsSchema, true);
      setRetrievedBorderStyles(true);
    }
  }, [setCurrentItem, currentItem, retrievedBorderStyles]);

  return (
    <div>
    <div className="editor__item__styles__item editor__item__styles__item--background">
      <label className="editor__item__content__heading editor__item__styles__item__heading">Choose a background colour for your place oddity</label>
      <button
        className="button b04 auto-width editor__item__content__heading__show-hide"
        onClick={(e) => {
          e.preventDefault();
          setBackgroundColorIsVisible(!backgroundColorIsVisible);
        }}
      >{backgroundColorIsVisible === true ? 'close' : 'Choose'}</button>
      {
        backgroundColorIsVisible === true &&
        <ItemEditorColorPicker name={'backgroundColor'} currentItem={currentItem} setCurrentItem={setCurrentItem} closeDialog={setBackgroundColorIsVisible} />
      }
    </div>
    <div className="editor__item__styles__item editor__item__styles__item--font">
        <label
          className="editor__item__content__heading editor__item__styles__item__heading"
          htmlFor="font"
        >Choose a font style for your place oddity</label>
      <select
          className="auto-width editor__item__content__select"
          name="font"
          onChange={(e) => {
            setCurrentItem({
              ...currentItem,
              fontStyle: e.target.value
            });
          }}
        >
          <option value="serif">Serif</option>
          <option value="sans-serif">Sans-Serif</option>
          <option value="monospace">Monospace</option>
          <option value="cursive">Cursive</option>
          <option value="fantasy">Fantasy</option>
        </select>
    </div>
      {
        borderStyleOptions[0] &&
        <div className="editor__item__styles__item editor__item__styles__item--border-styles">
          <div className="editor__item__styles__item__header">
            <label
              className="editor__item__content__heading editor__item__styles__item__heading"
              htmlFor="Border Style"
            >Select a border style for your place oddity</label>
            <select
              name="Border Style"
              id="borderStyle"
              onChange={(e) => {
                setCurrentItem({
                  ...currentItem,
                  borderStyle: e.target.value
                });
              }}
              value={currentItem.borderStyle}
            >
              {
                borderStyleOptions.map(
                  (option, i) => (
                    <option key={i} value={option}>{option}</option>
                  )
                )
              }
            </select>
          </div>
            {
              currentItem.borderStyle !== 'none' && currentItem.borderStyle !== 'icon' &&
              <div className="editor__item__styles__item__header">
                <label className="editor__item__content__heading editor__item__styles__item__heading">Choose a border colour for your place oddity (doesn’t apply if border style is set to <i>none</i> or <i>icon</i>)</label>
                <button
                  className="button b04 auto-width editor__item__content__heading__show-hide"
                  onClick={(e) => {
                    e.preventDefault();
                    setBorderColorIsVisible(!borderColorIsVisible);
                  }}
                >{borderColorIsVisible === true ? 'close' : 'Choose'}</button>
              </div>
            }
            {
              currentItem.borderStyle !== 'none' && currentItem.borderStyle !== 'icon' &&
              borderColorIsVisible === true &&
              <ItemEditorColorPicker name={'borderColor'} currentItem={currentItem} setCurrentItem={setCurrentItem} closeDialog={setBorderColorIsVisible} />
            }
          {
            currentItem.borderStyle !== 'none' && currentItem.borderStyle !== 'icon' &&
              <div className="editor__item__styles__item__header">
                <label
                  className="editor__item__content__heading editor__item__styles__item__heading"
                  htmlFor="Border Width"
                >Adjust the width of your place oddity’s border</label>
                <input
                  type="range"
                  name="Border Width"
                  min="0"
                  max="42"
                  value={currentItem.borderWidth ? currentItem.borderWidth : 6}
                  onChange={(e) => {
                    setCurrentItem({
                      ...currentItem,
                      borderWidth: e.target.value
                    });
                  }}
                />
              </div>
          }
        </div>
      }
    </div>
  )
}

export default ItemEditorStyles;